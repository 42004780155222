import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@mui/material'

const CameraStatusIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon data-testid="CameraStatusIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.9776 20C15.5128 20 20 15.5228 20 10C20 4.47715 15.5128 0 9.9776 0C4.76454 0 0.481077 3.97116 0.000121614 9.04698H5.60562L8.1247 4.64844L11.4655 11.3151L12.7645 9.04698H16.1819V10.9517H13.8723L11.3532 15.3503L8.01244 8.68361L6.71347 10.9517H0C0.480354 16.0282 4.7641 20 9.9776 20Z"
        fill={`${theme.palette.text.primary} !important`}
      />
    </SvgIcon>
  )
}

export default CameraStatusIcon
