import { useTranslation } from 'react-i18next'

import makeStyles from '@material-ui/core/styles/makeStyles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import LogoutMenuItem from 'shared-module/navigation/menu/items/LogoutMenuItem'
import LinkMenuItem from 'shared-module/navigation/menu/items/LinkMenuItem'
import MenuHeader from 'shared-module/navigation/menu/items/MenuHeader'
import VoskerLogoLight from 'vosker/src/assets/icons/VoskerLogoLight'
import VoskerHelpMenuItem from 'vosker/src/shared-module/navigation/menu/items/VoskerHelpMenuItem'
import CameraInfo from 'shared-module/navigation/menu/camera-items/CameraInfo'
import LiveStreamMenuItem from 'vosker/src/shared-module/navigation/menu/camera-items/LiveStreamMenuItem'

import LanguageOptions from './LanguageOptions'
import VoskerThemeMenuItem from './items/VoskerThemeMenuItem'
import VoskerEventIcon from 'vosker/src/assets/icons/VoskerEventIcon'
import SideSettingsIcon from 'vosker/src/assets/icons/SideSettingsIcon'
import CameraStatusIcon from 'vosker/src/assets/icons/CameraStatusIcon'
import AccountIcon from 'vosker/src/assets/icons/AccountIcon'
import HomeIcon from 'vosker/src/assets/icons/HomeIcon'
import PlanIcon from 'vosker/src/assets/PlanIcon'
import ActivateCameraIcon from 'vosker/src/assets/icons/ActivateCameraIcon'
import { useECommerceFlag } from 'vosker/src/launchDarkly-module/useEcommerceFlag'
import { useHomePageFlag } from 'vosker/src/launchDarkly-module/useHomePageFlag'
import SelectedCameraMenuItems from 'vosker/src/shared-module/navigation/menu/camera-items/SelectedCameraMenuItems.js'

const useStyles = makeStyles(theme => ({
  drawer: {
    height: '100%',
    background: theme.palette.background.paper,
    width: '360px',
    overflow: 'auto',
    scrollbarWidth: 'thin',
    scrollbarColor: 'transparent transparent',
    '&::-webkit-scrollbar': {
      width: '0px',
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'transparent',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  },
  topDivider: {
    borderTop: `solid 1px ${theme.palette.secondary.main}`,
  },
  bottomDivider: {
    borderBottom: `solid 1px ${theme.palette.secondary.main}`,
  },
  icon: {
    width: '24px !important',
    height: '24px !important',
  },
  homeIcon: {
    width: '1.875rem !important',
    height: '1.875rem !important',
    marginLeft: '-3px !important',
  },
  plusIcon: {
    width: '20px !important',
    height: '20px !important',
    marginLeft: '4px !important',
  },
  iconSpacing: {
    marginLeft: '2px !important',
  },
  list: {
    padding: 0,
  },
  currentCameraBox: {
    height: 88,
    padding: '16px 24px 18px 20px',
    background: `linear-gradient(70deg, ${theme.palette.primary?.dark} 85%,${theme.palette.primary?.light} 85%,${theme.palette.primary?.light},${theme.palette.primary?.light} 88.25%,${theme.palette.primary?.light} 88.25%, ${theme.palette.primary?.light} 150%)`,
  },
  selectedCamera: {
    borderBottom: `solid 1px ${theme.palette.secondary.main}`,
    padding: '0 12px 12px 12px',
    background: theme.palette.background.default,
  },
}))

const VoskerMenu = ({ open, onClose }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const camera = useSelectedCamera()
  const eCommerceLdEnabled = useECommerceFlag()
  const homePageEnabled = useHomePageFlag()

  return (
    <Drawer data-testid="VoskerMenu" disableScrollLock open={open} onClose={onClose} PaperProps={{ className: classes.drawer }}>
      <Grid container className={classes.container}>
        <Grid item>
          <MenuHeader iconClass={classes.icon} onClick={onClose} Logo={VoskerLogoLight} />

          { camera && (
            <>
              <Box className={classes.topDivider} />
              <List className={classes.list}>
                <LinkMenuItem id="Home" Icon={HomeIcon} text={t('menu.cameras')} iconClass={classes.homeIcon} onClick={onClose} path="/" />
              </List>
            </>
          ) }

          { camera && (
            <Box className={homePageEnabled ? classes.currentCameraBox : ''}>
              { !homePageEnabled && (
                <>
                  <Box className={classes.selectedCamera}>
                    <List className={classes.list}>
                      <CameraInfo />
                    </List>
                  </Box>

                  <SelectedCameraMenuItems onClose={onClose} />
                </>
              ) }
              <List className={classes.list}>
                <CameraInfo />
              </List>
            </Box>
          ) }

          <List className={classes.list}>
            { camera
              ? (
                <>
                  <LiveStreamMenuItem onClose={onClose} />
                  <LinkMenuItem id="Events" Icon={VoskerEventIcon} text="app:photo_gallery_page.title" path={`/camera/${camera.id}`} onClick={onClose} />
                  { camera?.isCellular && <LinkMenuItem id="Status" Icon={CameraStatusIcon} text="menu.camera_status" iconClass={classes.iconSpacing} path={`/camera/${camera.id}/status`} onClick={onClose} /> }
                  { camera?.isCellular && <LinkMenuItem id="Settings" Icon={SideSettingsIcon} text="menu.camera_settings" path={`/camera/${camera.id}/settings`} onClick={onClose} /> }
                  <LinkMenuItem id="Plans" Icon={PlanIcon} text="app:plan.page_title" path={`/camera/${camera.id}/plan`} onClick={onClose} />
                </>
                )
              : (
                <>
                  <Box className={classes.topDivider} />
                  <LinkMenuItem id="ActivateCamera" Icon={ActivateCameraIcon} text="menu.add_camera" onClick={onClose} path="/add-camera" />
                </>
                ) }
          </List>

          <Box className={classes.bottomDivider} />

          { camera && (
            <>
              <List className={classes.list}>
                <LinkMenuItem id="ActivateCamera" Icon={ActivateCameraIcon} text="menu.add_camera" onClick={onClose} path="/add-camera" />
              </List>
              <Box className={classes.bottomDivider} />
            </>
          ) }

          <List className={classes.list}>
            { !camera && <LinkMenuItem id="Home" Icon={HomeIcon} text={t('menu.cameras')} iconClass={classes.homeIcon} onClick={onClose} path="/" /> }
            <LinkMenuItem id="Account" Icon={AccountIcon} text={eCommerceLdEnabled ? t('app:menu.account') : t('app:menu.profile')} onClick={onClose} iconClass={classes.iconSpacing} path="/account/profile" />
            <LanguageOptions closeMenu={onClose} />
            <VoskerHelpMenuItem onClick={onClose} />
            <LogoutMenuItem iconClass={classes.iconSpacing} onClick={onClose} />
          </List>
        </Grid>

        <List className={classes.list}>
          <Box className={classes.topDivider} />
          <VoskerThemeMenuItem iconClass={classes.icon} closeMenu={onClose} />
        </List>

      </Grid>
    </Drawer>
  )
}

export default VoskerMenu
