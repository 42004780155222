import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'

import ClearFiltersIcon from 'assets/icons/photo/ClearFiltersIcon'
import useFilterCollection from '../core/filters.hooks'
import FilterCounter from './FilterCounter'

const useStyles = makeStyles(theme => ({
  clearText: {
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.fontFamily,
  },
  clearIcon: {
    marginLeft: 8,
    marginRight: -10,
    marginBottom: -13,
    marginTop: 3,
    fill: theme.palette.text.secondary + '!important',
  },
}))

const FiltersTopStatusBar = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { hasTimePeriod, selectedFilters, clearSelectedFilters, clearTimePeriod } = useFilterCollection()

  const onClick = () => {
    clearTimePeriod()
    clearSelectedFilters()
  }

  return (
    <Box display="flex" flexDirection="row" marginLeft={0.5}>
      <FilterCounter />

      { (selectedFilters.length !== 0 || hasTimePeriod) && (
        <Box pl={2.25} display="flex" flexDirection="row" alignItems="center">
          <ClearFiltersIcon className={classes.clearIcon} />
          <Link className={classes.clearText} component="button" onClick={onClick}>{ t('app:filters.buttons.clear_all') }</Link>
        </Box>
      ) }
    </Box>
  )
}

export default FiltersTopStatusBar
