import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useECommerceFlag } from 'vosker/src/launchDarkly-module/useEcommerceFlag'

import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'
import InfoRoundedIcon from '@material-ui/icons/InfoRounded'

import { formatDate } from 'shared-module/common-utils'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import { eCommerceTokenID } from 'vosker/src/ecommerce-module/core/eCommerce.selectors'
import useUser from 'user-module/user/core/useUser'
import VoskerTooltip from 'vosker/src/components/VoskerTooltip'
import { getPlanTranslationKey } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.selectors'
import { useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    margin: '13.5px 0 13.5px 0',
  },
  titleSection: {
    display: 'flex',
  },
  title: {
    fontSize: '1em',
    fontWeight: 'bold',
    display: 'inline',
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
  },
  date: {
    color: theme.palette.text.primary,
    textAlign: 'end',
  },
  note: {
    display: 'inline',
    paddingTop: '0.5rem',
    fontSize: theme.typography.caption1?.fontSize,
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.caption1?.fontFamily,
  },
  link: {
    color: theme.palette.status?.info,
    textDecoration: 'none !important',
  },
  infoIcon: {
    width: '1rem',
    height: '1rem',
    marginLeft: '0.25rem',
    marginBottom: '-0.215rem',
    fill: theme.palette.border?.secondary + ' !important',
  },
}))

const AutoRenewalManagement = () => {
  const classes = useStyles()
  const user = useUser()
  const { t, i18n } = useTranslation()
  const camera = useSelectedCamera()
  const theme = useTheme()
  const breakpoint = useMediaQuery(theme.breakpoints.down('xs'))

  const [showTooltip, setShowTooltip] = useState(false)

  const subscription = camera?.subscription
  const plan = subscription?.plan
  const isCompletingTrial = camera.isCompletingTrial
  const onFreePlan = !isCompletingTrial && plan.isFree
  const onTrial = !isCompletingTrial && subscription.isFree && !plan.isFree
  const showAutoRenew = !onFreePlan
  const eCommerceLdEnabled = useECommerceFlag()

  const auth = localStorage.getItem(eCommerceTokenID)
  const redirectionURL = window.location.origin + `/camera/${camera.id}/plan`
  const language = user.language === 'en' || user.language === 'fr' ? user.language : 'en'
  const countryCode = user.country === 'US' || user.country === 'CA' ? user.country.toLowerCase() : 'ca'

  const origin = window.location.hostname === 'localhost'
    ? 'dev.vosker'
    : window.location.hostname.replace('webapp', '').replace('.com', '').substring(1)

  const url = `https://cc.${origin}.com/${countryCode}/${language}/account/external?auth=${auth}&url=${redirectionURL}`

  const handleTooltipOpening = () => {
    if (breakpoint) {
      setShowTooltip(true)
      setTimeout(() => setShowTooltip(false), 5000)
    }
  }

  if (!eCommerceLdEnabled || !showAutoRenew) {
    return null
  }

  return eCommerceLdEnabled && showAutoRenew && (
    <>
      <Divider />
      <Grid container className={classes.container}>
        <Grid item xs={6}>
          <Typography className={classes.title}>
            { subscription.isAutoRenew || (isCompletingTrial && camera?.upcomingSubscription)
              ? t('app:plan.current_subscription.auto_renewal.management.next_billing.label')
              : t('app:plan.current_subscription.auto_renewal.management.plan_ended.label') }

            { ' ' }

            { !onTrial && !isCompletingTrial && (
              <text onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)} onTouchStart={handleTooltipOpening}>
                <VoskerTooltip
                  arrow
                  open={showTooltip}
                  title={subscription.isAutoRenew
                    ? t('app:plan.current_subscription.auto_renewal.management.next_billing.tooltip')
                    : t('app:plan.current_subscription.auto_renewal.management.plan_ended.tooltip', { date: formatDate(subscription.endDateBillingCycle, i18n) })}
                >
                  <InfoRoundedIcon data-testid="auto-renewal-tooltip" className={classes.infoIcon} />
                </VoskerTooltip>
              </text>
            ) }

            { isCompletingTrial && camera?.upcomingSubscription && (
              <text onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)} onTouchStart={handleTooltipOpening}>
                <VoskerTooltip
                  arrow
                  open={showTooltip}
                  title={t(`app:plan.current_subscription.warnings.free_trial_extended_${camera.upcomingSubscription.paymentFrequency}`, {
                    upcomingPlanName: t('app:plans.' + getPlanTranslationKey(camera?.upcomingSubscription?.plan?.name)).toLocaleUpperCase(),
                  })}
                >
                  <InfoRoundedIcon data-testid="auto-renewal-tooltip" className={classes.infoIcon} />
                </VoskerTooltip>
              </text>
            ) }
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography className={classes.date}>
            { formatDate(subscription.endDateBillingCycle, i18n, true, { month: 'short' }) }
          </Typography>
        </Grid>
      </Grid>

      { !onTrial && (
        <Grid item container>
          <Typography className={classes.note}>
            { t('app:plan.current_subscription.auto_renewal.management.link.line_one') }
            { ' ' }
            <Link to={{ pathname: url }} target="_blank" className={classes.link}>{ t('app:plan.current_subscription.auto_renewal.management.link.subscription') }</Link>
            { ' ' }
            { t('app:plan.current_subscription.auto_renewal.management.link.line_two') }
          </Typography>
        </Grid>
      ) }
    </>
  )
}

export default AutoRenewalManagement
