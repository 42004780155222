import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'

import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import { getPlanTranslationKey } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.selectors'
import paymentFrequency from 'vosker/src/order-module/order/core/paymentFrequency'
import { useECommerceFlag } from 'vosker/src/launchDarkly-module/useEcommerceFlag'

const useStyles = makeStyles(theme => ({
  warning: {
    color: theme.palette.text.secondary,
    fontSize: '.9em',
    lineHeight: '1.5em',
    marginTop: '1.5rem',
  },
}))

const Warning = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const camera = useSelectedCamera()
  const eCommerceLdEnabled = useECommerceFlag()

  const subscription = camera?.subscription
  const plan = subscription?.plan
  const upcomingSubscription = camera?.upcomingSubscription
  const upcomingPlan = upcomingSubscription?.plan

  const isCompletingTrial = camera.isCompletingTrial
  const onTrial = !isCompletingTrial && subscription.isFree && !plan.isFree
  const onFreePlan = !isCompletingTrial && plan.isFree
  const hasFreePlanAfterTrial = !!camera.hasFreePlan
  const onMonthTrial = onTrial && subscription.paymentFrequency === paymentFrequency.oneMonthTrial
  const onSevenDaysTrial = onTrial && subscription.paymentFrequency === paymentFrequency.sevenDaysTrial

  const showAutoRenew = !onTrial && !onFreePlan
  const isAutoRenew = subscription.isAutoRenew

  const translatedPlanName = t('app:plans.' + getPlanTranslationKey(plan?.name)).toLocaleUpperCase()
  const translatedUpcomingPlanName = t('app:plans.' + getPlanTranslationKey(upcomingPlan?.name)).toLocaleUpperCase()

  const renderWarning = warning => <Typography className={classes.warning} data-testid="subscription-warning">{ warning }</Typography>

  if (isCompletingTrial && !eCommerceLdEnabled) {
    if (upcomingSubscription && upcomingPlan) {
      return renderWarning(t(`app:plan.current_subscription.warnings.free_trial_extended_${upcomingSubscription.paymentFrequency}`, {
        upcomingPlanName: translatedUpcomingPlanName,
      }))
    }
    return renderWarning(t(`app:plan.current_subscription.warnings.free_trial_extended_${subscription.paymentFrequency}`))
  }

  if (showAutoRenew && !isAutoRenew && !eCommerceLdEnabled) {
    return renderWarning(
      hasFreePlanAfterTrial
        ? t('app:plan.current_subscription.warnings.auto_renew_disabled')
        : t('app:plan.current_subscription.warnings.auto_renew_disabled_no_plan'),
    )
  }

  if (onSevenDaysTrial) {
    return renderWarning(t('app:plan.current_subscription.warnings.free_seven_days', { planName: translatedPlanName }))
  }

  if (onMonthTrial) {
    return renderWarning(t('app:plan.current_subscription.warnings.free_month', { planName: translatedPlanName }))
  }

  return null
}

export default Warning
