import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@mui/material'

const InfoIcon = ({ className, props }) => {
  const theme = useTheme()

  return (
    <SvgIcon data-testid="InfoIcon" width={24} height={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className} style={{ ...props }}>
      <path
        d="M3.824 12A8.175 8.175 0 0 1 12 3.824 8.175 8.175 0 0 1 20.176 12 8.175 8.175 0 0 1 12 20.176 8.175 8.175 0 0 1 3.824 12ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11.078 5.344h-2.144V15.37h2.144ZM13.07 14h-2.136V6.5h2.136Zm0 0"
        stroke="none"
        fillRule="evenodd"
        fill={theme.palette.text.primary + '!important'}
        fillOpacity={1}
      />
    </SvgIcon>
  )
}

export default InfoIcon
