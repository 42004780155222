import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'

import SelectedCamera from './SelectedCamera'
import CurrentSubscription from './CurrentSubscription'
import webview from 'shared-module/webview/webview'

const useStyles = makeStyles((theme) => ({
  container: {
    marginRight: 20,
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
  currentSubscriptionContainer: {
    background: theme.palette.background.paper,
    borderRadius: '6px',
    width: '100%',
    padding: '24px 32px 32px 32px',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
    },
  },
  panel: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      padding: '0 0 0 25px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  cameraAndMeterContainer: {
    width: '100%',
    display: 'flex',
    height: 88,
    background: theme.palette.background.paper,
    borderRadius: 6,
    padding: 14,
    margin: 24,
    position: 'relative',
    top: '-24px',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: '2rem',
      width: '100%',
      marginRight: 0,
    },
    [theme.breakpoints.down('sm')]: {
      margin: '24px 0 0',
    },
  },
}))

const Header = ({ isNewUi = false, eCommerceIsDown = false }) => {
  const classes = useStyles()
  const isWebview = webview.isWebview()

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item className={classes.panel} sm={12} md={5}>
          { !isWebview && (
            <div className={classes.cameraAndMeterContainer}>
              <SelectedCamera />
            </div>
          ) }
        </Grid>
        <Grid item className={classes.panel} sm={12} md={7}>
          <div className={classes.currentSubscriptionContainer}>
            <CurrentSubscription isNewUi={isNewUi} eCommerceIsDown={eCommerceIsDown} />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default Header
