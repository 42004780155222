import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import MenuItem from 'shared-module/navigation/menu/MenuItem'
import { useHomePageFlag } from 'vosker/src/launchDarkly-module/useHomePageFlag'

const LinkMenuItem = ({ id, Icon, text, path, onClick, iconClass, children }) => {
  const history = useHistory()
  const homePageEnabled = useHomePageFlag()

  const [selectedItem, setSelectedItem] = useState()

  useEffect(() => {
    homePageEnabled && history.location.pathname === path && setSelectedItem(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, path])

  const navigateToPath = () => {
    onClick()
    history.push(path)
  }

  return (
    <MenuItem
      id={id}
      Icon={Icon}
      text={text}
      onClick={navigateToPath}
      iconClass={iconClass}
      isSelected={selectedItem}
    >
      { children }
    </MenuItem>
  )
}

export default LinkMenuItem
