import jwtDecode from 'jwt-decode'
import { basketTokenID, eCommerceLDFlag, eCommerceRefreshTokenID, eCommerceTokenID, eCommerceUnreachable } from 'vosker/src/ecommerce-module/core/eCommerce.selectors'

const tokenId = 'token'
const refreshId = 'refresh'
const deviceId = 'device'
const keysToRemove = [tokenId, refreshId, eCommerceTokenID, eCommerceRefreshTokenID, basketTokenID, eCommerceLDFlag, eCommerceUnreachable]

const get = () => localStorage.getItem(tokenId)
const getRefresh = () => localStorage.getItem(refreshId)
const getDevice = () => localStorage.getItem(deviceId)
const set = token => localStorage.setItem(tokenId, token)
const setRefresh = refresh => localStorage.setItem(refreshId, refresh)
const setDevice = device => localStorage.setItem(deviceId, device)
const remove = () => keysToRemove.forEach(key => localStorage.removeItem(key))
const decodeUserId = () => jwtDecode(get()).user._id

export default { get, getRefresh, getDevice, set, setRefresh, setDevice, remove, decodeUserId }
