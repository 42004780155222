import BatteryEmpty from 'assets/icons/status/new-status-icons/BatteryEmpty'
import BatteryLow from 'assets/icons/status/new-status-icons/BatteryLow'
import BatteryMedium from 'assets/icons/status/new-status-icons/BatteryMedium'
import BatteryHigh from 'assets/icons/status/new-status-icons/BatteryHigh'

export const getBatteryIcon = (percentage) => {
  if (percentage <= 0) return BatteryEmpty
  if (percentage >= 1 && percentage <= 34) return BatteryLow
  if (percentage >= 35 && percentage <= 74) return BatteryMedium
  if (percentage >= 75 && percentage <= 100) return BatteryHigh
  return BatteryEmpty
}
