import { useTheme } from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'
const MoreIcon = (props) => {
  const theme = useTheme()
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="-2 -2 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 12C7 13.3807 5.88071 14.5 4.5 14.5C3.11929 14.5 2 13.3807 2 12C2 10.6193 3.11929 9.5 4.5 9.5C5.88071 9.5 7 10.6193 7 12Z" fill={theme.palette.text.primary} />
      <path d="M14.5 12C14.5 13.3807 13.3807 14.5 12 14.5C10.6193 14.5 9.5 13.3807 9.5 12C9.5 10.6193 10.6193 9.5 12 9.5C13.3807 9.5 14.5 10.6193 14.5 12Z" fill={theme.palette.text.primary} />
      <path d="M22 12C22 13.3807 20.8807 14.5 19.5 14.5C18.1193 14.5 17 13.3807 17 12C17 10.6193 18.1193 9.5 19.5 9.5C20.8807 9.5 22 10.6193 22 12Z" fill={theme.palette.text.primary} />
    </SvgIcon>
  )
}
export default MoreIcon
