import { createTheme } from '@material-ui/core'
import responsiveFontSizes from '@material-ui/core/styles/responsiveFontSizes'

import { typographies } from 'vosker/src/shared-module/theme/typographies'

const colors = {
  baseWhite: '#FFFFFF',
  baseBlack: '#000000',
  baseGrey: '#F0F1F5',
  baseLightGrey: '#F0F1F5',

  primaryColor: '#FACB2C',
  primaryLight: '#FCD656',
  primaryDark: '#F9C20A',

  secondaryColor: '#474F6B',
  secondaryLight: '#525B7A',
  secondaryDark: '#3D445C',

  textColor: '#F0F1F5',
  textContrastColor: '#B3B8CC',

  errorColor: '#FF6961',
  errorContrastColor: '#501717',

  warningColor: '#F9C20A',
  warningContrastColor: '#3F3C38',
  warningDarkColor: '#191301',
  warningBlue: '#1B76FD',

  cautionColor: '#FFA24B',
  cautionContrastColor: '#743800',

  successColor: '#6FB988',
  successContrastColor: '#1B3524',

  infoColor: '#3F86F2',
  infoBackground: '#2F456F',
  infoContrastColor: '#202D4B',

  backgroundRegular: '#292D3D',
  backgroundSecondary: '#33394D',
  backgroundDark: '#3D445C',
  backgroundShadow: '#1F222E',
  backgroundConsent: '#303548',
  zoneShadow: '#212532',

  borderRegular: '#474F6B',
  borderDark: '#949CB8',
  borderLight: '#B3B8CC',
  logo: '#292D3D',

  transparent: '#00000000',
}

const muiTheme = createTheme({
  typography: typographies,
  palette: {
    type: 'dark',
    primary: {
      main: colors.primaryColor,
      light: colors.primaryLight,
      dark: colors.primaryDark,
    },
    secondary: {
      main: colors.secondaryColor,
      light: colors.secondaryLight,
      dark: colors.secondaryDark,
    },
    text: {
      primary: colors.textColor,
      secondary: colors.textContrastColor,
      contrastText: colors.backgroundRegular,
      main: colors.baseBlack,
    },
    status: {
      error: colors.errorColor,
      caution: colors.cautionColor,
      warning: colors.warningColor,
      success: colors.successColor,
      info: colors.infoColor,
      background: {
        error: colors.errorContrastColor,
        caution: colors.cautionContrastColor,
        warning: colors.warningContrastColor,
        warningDark: colors.warningDarkColor,
        success: colors.successContrastColor,
        info: colors.infoContrastColor,
      },
    },
    background: {
      default: colors.backgroundRegular,
      paper: colors.backgroundSecondary,
      contrast: colors.backgroundSecondary,
      dark: colors.textContrastColor,
      light: colors.backgroundDark,
      tile: colors.backgroundDark,
      logo: colors.baseWhite,
      shadow: colors.backgroundShadow,
      boxShadow: colors.zoneShadow,
      paperShadow: colors.backgroundShadow + '80',
      empty: colors.backgroundDark,
      viewer: colors.baseBlack,
      consent: colors.backgroundDark,
      dropdown: colors.borderRegular,
      gradient: colors.backgroundRegular,
      voskerLogo: colors.baseWhite,
      info: colors.infoBackground,
    },
    border: {
      primary: colors.borderRegular,
      secondary: colors.borderDark,
      contrast: colors.borderRegular,
      neutral: colors.baseWhite,
      light: colors.borderLight,
      divider: colors.borderRegular,
    },
    basic: {
      white: colors.baseWhite,
      black: colors.baseBlack,
      grey: colors.baseGrey,
      lightGrey: colors.baseLightGrey,
      dark: colors.backgroundShadow,
      transparent: colors.transparent,
    },
    icon: {
      primary: colors.borderDark,
      icon: colors.borderLight,
      iconDark: colors.borderDark,
      iconShadow: colors.backgroundRegular,
      iconPromo: colors.backgroundShadow,
      iconBlue: colors.infoColor,
      iconBorder: colors.textContrastColor,
      iconConsent: colors.backgroundConsent,
      iconNoImg: colors.secondaryColor,
    },
    switch: {
      background: colors.backgroundSecondary,
      backgroundActive: colors.borderRegular,
    },
    checkbox: {
      primary: colors.secondaryLight,
    },
  },
  overrides: {
    MuiLink: {
      root: {
        color: colors.textContrastColor,
        '&:hover': {
          filter: 'brightness(115%)',
        },
      },
    },
    MuiBadge: {
      colorError: {
        backgroundColor: colors.errorColor,
      },
    },
    MuiSvgIcon: {
      root: {
        fill: colors.textColor + ' !important',
      },
    },
    MuiTooltip: {
      tooltip: {
        maxWidth: 250,
        boxShadow: 'none',
        fontSize: '0.688rem !important',
        textAlign: 'center',
        color: colors.baseBlack,
        backgroundColor: colors.textColor,
      },
      arrow: {
        color: colors.textColor,
      },
    },
    MuiBackdrop: {
      root: {
        background: colors.backgroundShadow + 'B2 !important',
      },
    },
    // MuiButton: {
    //   contained: {
    //     '&.Mui-disabled': {
    //       color: colors.textContrastColor + 'CC !important',
    //       backgroundColor: colors.secondaryColor + '80 !important',
    //     },
    //   },
    // },
  },
})

const theme = responsiveFontSizes(muiTheme)
export default theme
