import { useTheme } from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'
const InfinityIcon = (className, props) => {
  const theme = useTheme()

  return (
    <SvgIcon
      data-testid="infinity-icon"
      className={className}
      viewBox="0 0 32 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect />
      <g clipPath="url(#clip0_13355_15676)">
        <path fillRule="evenodd" clipRule="evenodd" d="M9.45283 9.99745C9.45283 8.49308 10.6767 7.26973 12.1912 7.26973C13.0407 7.26973 13.8023 7.88761 14.4173 8.70662C14.7106 9.0973 14.9386 9.49235 15.0938 9.79196C15.1708 9.94086 15.2287 10.0639 15.2667 10.1481C15.2856 10.1901 15.2995 10.2222 15.3083 10.2428L15.3175 10.2648L15.319 10.2686L15.3198 10.2704L15.3213 10.2739L15.3256 10.2845C15.3293 10.2933 15.3343 10.3054 15.3406 10.3203C15.3534 10.3504 15.3716 10.3925 15.3953 10.4449C15.4427 10.5498 15.5119 10.6968 15.6026 10.8718C15.7832 11.22 16.0531 11.6888 16.4091 12.1619C17.0934 13.0713 18.2356 14.1793 19.8073 14.1793C22.1207 14.1793 23.9999 12.3091 23.9999 9.9973C23.9999 7.68553 22.1207 5.8153 19.8073 5.8153C18.2895 5.8153 17.1729 6.84942 16.4859 7.73229L17.6341 8.62566C18.2423 7.84398 18.9829 7.27008 19.8073 7.27008C21.3215 7.27008 22.5452 8.49321 22.5452 9.9973C22.5452 11.5014 21.3215 12.7245 19.8073 12.7245C18.9515 12.7245 18.1871 12.1053 17.5715 11.2873C17.2777 10.8968 17.0495 10.5019 16.8943 10.2024C16.8172 10.0536 16.7593 9.9306 16.7213 9.84651C16.7024 9.80454 16.6885 9.77246 16.6798 9.75188L16.6705 9.72984L16.669 9.72613L16.6682 9.72446L16.6668 9.72096L16.6624 9.71027C16.6588 9.70147 16.6538 9.68947 16.6474 9.67448C16.6347 9.64452 16.6165 9.6024 16.5928 9.54995C16.5455 9.44514 16.4762 9.2982 16.3857 9.12319C16.2054 8.77499 15.9359 8.30627 15.5807 7.83315C14.8985 6.92462 13.7588 5.81494 12.1912 5.81494C9.87751 5.81494 7.99805 7.68541 7.99805 9.99745C7.99805 12.3095 9.87751 14.18 12.1912 14.18C13.7052 14.18 14.8193 13.1442 15.5041 12.262L14.355 11.3699C13.7474 12.1526 13.0095 12.7252 12.1912 12.7252C10.6767 12.7252 9.45283 11.5018 9.45283 9.99745Z" fill={theme.palette?.text?.primary} />
      </g>
      <defs>
        <clipPath id="clip0_13355_15676">
          <rect
            width="16"
            height="16"
            transform="translate(8 2)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}
export default InfinityIcon
