import { styled } from '@mui/material/styles'
import { PickersDay } from '@mui/x-date-pickers/PickersDay'
import { useTheme } from '@material-ui/core'

const CustomPickersDay = styled(PickersDay, { shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered' && prop !== 'isCurrentDate' })(({ isSelected, isHovered, isCurrentDate }) => {
  const theme = useTheme()

  return ({
    borderRadius: '50%',
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily + '!important',
    '&.MuiPickersDay-root': {
      '&:focus': {
        color: theme.palette.basic?.lightGrey + '!important',
        backgroundColor: theme.palette.status?.info + '!important',
      },
      '&.Mui-disabled': {
        color: theme.palette.text.primary + '!important',
        opacity: 0.4,
      },
      [theme.breakpoints.down('xs')]: {
        width: 44,
        height: 44,
      },
    },
    '&:hover': {
      color: theme.palette.status?.info + '!important',
      backgroundColor: theme.palette.background?.info,
    },
    ...(isSelected && !isHovered && {
      backgroundColor: theme.palette.status?.info,
      color: theme.palette.basic?.lightGrey + '!important',
    }),
    ...(isSelected && {
      '&:hover': {
        color: theme.palette.basic?.lightGrey + '!important',
        backgroundColor: theme.palette.status?.info + '!important',
      },
    }),
    ...(isCurrentDate && {
      '&.MuiPickersDay-root:not(.Mui-selected)': {
        border: `1px solid ${theme.palette.text.primary} !important`,
      },
    }),
    ...(isHovered && {
      color: theme.palette.status?.info + '!important',
      backgroundColor: theme.palette.background?.info,
      '&:hover': {
        color: theme.palette.basic?.lightGrey + '!important',
        backgroundColor: theme.palette.status?.info + '!important',
      },
    }),
  })
})

const CustomPickerWrapper = (props) => {
  const { day, selectedDay, previousSelectedDay, currentDate, hoveredDay, ...other } = props

  const isCurrentDate = () => {
    if (currentDate == null) {
      return null
    }

    return (currentDate.$D === day.$D && currentDate.$M === day.$M && currentDate.$y === day.$y)
  }

  const isSelected = () => {
    if (selectedDay == null) {
      return false
    }

    return day.isSame(previousSelectedDay) || day.isSame(selectedDay)
  }

  const isBetween = () => {
    if (selectedDay == null) {
      return false
    }

    return previousSelectedDay.isSame(selectedDay)
      ? day.isBetween(selectedDay, hoveredDay)
      : day.isBetween(selectedDay, previousSelectedDay)
  }

  return (
    <CustomPickersDay
      id="CustomPickersDay"
      {...other}
      day={day}
      selected={false}
      isSelected={isSelected()}
      isHovered={isBetween()}
      isCurrentDate={isCurrentDate()}
    />
  )
}

export default CustomPickerWrapper
