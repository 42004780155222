export const eCommerceTokenID = 'salesForceToken'
export const eCommerceRefreshTokenID = 'salesForceRefreshToken'
export const eCommerceLDFlag = 'eCommerceLDFlag'
export const basketTokenID = 'basketId'
export const eCommerceUnreachable = 'not_available'

const salesForcePlansIds = 'D06220,D06221,D06230,D06231,D06240,D06241'
const salesForceAddonsIds = 'D06251'

const commerceSelector = { salesForcePlansIds, salesForceAddonsIds }
export default commerceSelector
