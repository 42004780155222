import { createContext, useContext, useEffect } from 'react'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import useUser from 'user-module/user/core/useUser'

const FlagsContext = createContext({})
export const useLDFlags = () => useContext(FlagsContext)
const FlagsProviderApp = ({ children, ldClient }) => {
  const user = useUser()

  useEffect(() => {
    if (ldClient && user.id) {
      ldClient.identify({
        kind: 'user',
        key: user.id,
      })
    }
  }, [ldClient, user.id])

  const flags = ldClient?.allFlags()

  return (
    <FlagsContext.Provider value={{ flags }}>
      { children }
    </FlagsContext.Provider>
  )
}
export const FlagsProvider = withLDConsumer()(FlagsProviderApp)
