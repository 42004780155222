import { useMemo } from 'react'
import useUser from 'user-module/user/core/useUser'
import useLanguage from 'shared-module/components/language/useLanguage'

import { FREQUENCY } from './discount.types'
import { formatDiscount, getRawDiscount } from './discount.selectors'

import { useCameraPlans } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.hook'
import { apiCameraPlans } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.types'
import { useECommerceProducts } from 'vosker/src/ecommerce-module/core/eCommerce.hook'
import { useECommerceFlag } from 'vosker/src/launchDarkly-module/useEcommerceFlag'

export const useDiscount = (cameraPlan, frequency = FREQUENCY.yearly) => {
  const language = useLanguage()
  const currency = useUser()?.currency || 'USD'

  return formatDiscount(getRawDiscount(cameraPlan, frequency), currency, language)
}

export const useBestYearlyDiscount = () => {
  let bestDiscount = 0
  const language = useLanguage()
  const currency = useUser()?.currency || 'USD'
  const plans = useCameraPlans()
  const eCommerceProducts = useECommerceProducts()

  const eCommerceLdEnabled = useECommerceFlag()

  const cameraPlans = useMemo(() => {
    return !eCommerceLdEnabled
      ? plans?.plans.filter(plan => plan.id !== apiCameraPlans.empty)
      : []
  }, [eCommerceLdEnabled, plans])

  cameraPlans && cameraPlans.forEach(cameraPlan => {
    const discount = getRawDiscount(cameraPlan, FREQUENCY.yearly)
    if (discount?.value > (bestDiscount?.value || 0)) {
      bestDiscount = discount
    }
  })

  const compareDiscount = () => {
    const allDiscounts = eCommerceProducts?.plans && eCommerceProducts.plans
      .map(plan => plan.discount)
      .filter(discount => discount !== false)
      .every((val, i, arr) => val === arr[0])
    const lastPlanWithDiscount = eCommerceProducts?.plans
      .slice()
      .reverse()
      .find(plan => plan.discount)
    return allDiscounts && lastPlanWithDiscount?.discount
  }

  return eCommerceLdEnabled
    ? compareDiscount()
    : formatDiscount(bestDiscount, currency, language)
}
