import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const LogoutIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-testid="LogoutIcon">
      <path
        d="M2.4063 3C2.4063 2.44772 2.85401 2 3.4063 2H12.0427V4H4.4063V20H12.0427V22H3.4063C2.85401 22 2.4063 21.5523 2.4063 21V3Z"
        fill={theme.palette.text.primary}
      />
      <path
        d="M18.1739 13H7.49718V11H18.1739L14.9719 7.798L16.3861 6.38379L21.2952 11.2929C21.6857 11.6834 21.6857 12.3166 21.2952 12.7071L16.3861 17.6162L14.9719 16.202L18.1739 13Z"
        fill={theme.palette.text.primary}
      />
    </SvgIcon>
  )
}

export default LogoutIcon
