import * as actions from './filters.actions'
import filterCollectionState from './filters.state'

const filterCollectionReducer = (state = filterCollectionState.defaultState, action) => {
  switch (action.type) {
    case actions.SET_FILTER_COLLECTION_ACTION:
      return filterCollectionState.set(state, action)
    case actions.SET_SELECTED_FILTERS_ACTION:
      return filterCollectionState.setSelectedFilters(state, action)
    case actions.REMOVE_SELECTED_FILTERS_ACTION:
      return filterCollectionState.removeSelectedFilters(state, action)
    case actions.CLEAR_SELECTED_FILTERS_ACTION:
      return filterCollectionState.clearSelectedFilters(state, action)
    case actions.SET_TIME_PERIOD_ACTION:
      return filterCollectionState.setTimePeriod(state, action)
    case actions.CLEAR_TIME_PERIOD_ACTION:
      return filterCollectionState.clearTimePeriod(state, action)

    default:
      return state
  }
}

export default filterCollectionReducer
