const clientSideID = {
  dev: '6627b869b599891004dfdaef',
  staging: '6627b81c2624761004b89dfb',
  prod: '6627b6a98e9c7f1054cc1e6f',
}
const envMapping = {
  'dev.vosker.com': clientSideID.dev,
  'staging.vosker.com': clientSideID.staging,
  'vosker.com': clientSideID.prod,
}
export const getClientSideId = () =>
  envMapping[Object.keys(envMapping).find(key => window.location.hostname.includes(key))] || clientSideID.dev
