import { useTheme } from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'

const VoskerEventIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon data-testid="VoskerEventIcon" width="16" height="16" viewBox={props.viewBox || '0 0 16 16'} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.81143 3.82063L4.81143 12.0258C4.50393 12.0258 4.20903 11.9048 3.9916 11.6896C3.77417 11.4743 3.65201 11.1824 3.65201 10.878L3.65201 4.96841C3.65201 4.664 3.77417 4.37206 3.9916 4.15681C4.20903 3.94156 4.50393 3.82063 4.81143 3.82063Z"
        fill={theme.palette.text.primary}
      />

      <path
        d="M2.49259 3.82063L2.49259 12.0258C2.18509 12.0258 1.89019 11.9048 1.67276 11.6896C1.45532 11.4743 1.33317 11.1824 1.33317 10.878L1.33317 4.96841C1.33317 4.664 1.45532 4.37206 1.67276 4.15681C1.89019 3.94156 2.1851 3.82063 2.49259 3.82063Z"
        fill={theme.palette.text.primary}
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6409 12.0257C14.2073 12.0257 14.6665 11.5665 14.6665 11.0001L14.6665 4.84624C14.6665 4.27979 14.2073 3.8206 13.6409 3.8206L6.9742 3.8206C6.40775 3.8206 5.94856 4.27979 5.94856 4.84624L5.94856 11.0001C5.94856 11.5665 6.40775 12.0257 6.9742 12.0257L13.6409 12.0257ZM7.40511 9.20168L10.2256 9.20168L13.0461 9.20168L10.9937 6.16156L10.3428 7.04068L9.69189 7.9198L9.041 7.04068L7.40511 9.20168Z"
        fill={theme.palette.text.primary}
      />

    </SvgIcon>
  )
}

export default VoskerEventIcon
