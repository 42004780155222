import { useTranslation } from 'react-i18next'
import { HashLink } from 'react-router-hash-link'

import LanguageIcon from '@material-ui/icons/Language'
import makeStyles from '@material-ui/core/styles/makeStyles'
import LinkMenuItem from 'shared-module/navigation/menu/items/LinkMenuItem'

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
    marginBottom: 10,
  },
  item: {
    paddingLeft: theme.spacing(4),
    '&:hover': {
      background: theme.palette.background.paper,
    },
  },
  languageButton: {
    background: 'transparent',
    border: 'none',
    color: theme.palette.text.primary,
    cursor: 'pointer',
    fontSize: theme.typography.body1.fontSize,
    fontFamily: theme.typography.body1.fontFamily,
    paddingLeft: 0,
    '&::after': {
      content: '"|"',
      display: 'inline-block',
      marginLeft: 14,
      marginRight: 10,
      fontWeight: 'normal',
      color: theme.palette.text.primary,
      cursor: 'default',
    },
    '&:last-child::after': {
      display: 'none',
    },
  },
  hashLink: {
    textDecoration: 'unset',
    color: 'inherit',
  },
  active: {
    textDecoration: 'underline',
  },
}))

const LanguageOptions = ({ closeMenu, iconClass }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <HashLink className={classes.hashLink} to="/account/profile#profilepreferences">
      <LinkMenuItem
        id="Language"
        Icon={LanguageIcon}
        onClick={closeMenu}
        path="/account/profile#profilepreferences"
        text={t('languages')}
        iconClass={iconClass}
      />
    </HashLink>
  )
}

export default LanguageOptions
