import { Route } from 'react-router-dom'
import { BasketProvider } from '../core/basket/BasketContext'
import { useECommerceFlag } from 'vosker/src/launchDarkly-module/useEcommerceFlag'

const BasketRoute = ({ component: Component, ...rest }) => {
  const eCommerceLdEnabled = useECommerceFlag()

  return (
    <Route
      {...rest}
      render={(props) => (
        eCommerceLdEnabled
          ? (
            <BasketProvider>
              <Component {...props} />
            </BasketProvider>
            )
          : (
            <Component {...props} />
            )
      )}
    />
  )
}

export default BasketRoute
