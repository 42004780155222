import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import useUser from 'user-module/user/core/useUser'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import paymentFrequency from 'vosker/src/order-module/order/core/paymentFrequency'
import Amount from 'shared-module/components/amount/Amount'
import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'
import { useECommerceProducts } from 'vosker/src/ecommerce-module/core/eCommerce.hook'

const useStyles = makeStyles(theme => ({
  payment: {
    color: theme.palette.text.primary,
    textAlign: 'end',
  },
  paymentInfo: {
    fontFamily: theme.typography.fontWeightMedium,
    fontWeight: 'bold',
    fontSize: '1em',
    color: theme.palette.text.primary,
  },
  billingCycle: {
    fontSize: '1em',
    color: theme.palette.text.secondary,
  },
  price: {
    fontFamily: theme.typography.fontWeightMedium,
    fontWeight: 'bold',
    fontSize: '1em',
  },
  sectionContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '13.5px 0 13.5px 0',
  },
  trialPaymentInfo: {
    textDecoration: 'line-through',
  },
}))

const PaymentInfo = ({ eCommerceIsDown = false }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const user = useUser()
  const camera = useSelectedCamera()
  const eCommerceProducts = useECommerceProducts()

  const subscription = camera?.subscription
  const plan = subscription?.plan
  const upcomingSubscription = camera?.upcomingSubscription
  const upcomingPlan = upcomingSubscription?.plan

  const isCompletingTrial = camera.isCompletingTrial
  const onTrial = !isCompletingTrial && subscription.isFree && !plan.isFree
  const onFreePlan = !isCompletingTrial && plan.isFree

  const onMonthlyPlan = subscription.paymentFrequency === paymentFrequency.monthly
  const onYearlyPlan = subscription.paymentFrequency === paymentFrequency.yearly
  const upcomingMonthlyPlan = upcomingSubscription?.paymentFrequency === paymentFrequency.monthly

  const eCommerceEnabled = appConfig.eCommerce.switch === switches.on
  const discountIfPaidAnnually = (() => {
    if (!eCommerceEnabled || !Array.isArray(eCommerceProducts?.plans)) {
      return null
    }

    if (eCommerceIsDown) {
      return null
    }

    const lastPlanWithDiscount = eCommerceProducts.plans
      .slice()
      .reverse()
      .find(plan => plan?.discount)

    if (lastPlanWithDiscount?.discount) {
      const discountValue = parseInt(lastPlanWithDiscount.discount.slice(0, -1), 10)
      return 1 - discountValue / 100
    }

    return null
  })()

  let price
  if (onMonthlyPlan || onTrial) {
    price = plan.pricePerMonthIfPaidPerMonth
  }
  if (onYearlyPlan) {
    price = eCommerceEnabled ? plan.pricePerMonthIfPaidAnnually * discountIfPaidAnnually : plan.pricePerMonthIfPaidAnnually
  }
  if (upcomingPlan) {
    price = upcomingMonthlyPlan ? upcomingPlan.pricePerMonthIfPaidPerMonth : upcomingPlan.pricePerMonthIfPaidAnnually
  }
  if (onFreePlan) {
    price = 0
  }

  let currentPaymentFrequency
  if (!upcomingSubscription && !onTrial && !onFreePlan) {
    currentPaymentFrequency = subscription.paymentFrequency
  } else if (upcomingSubscription) {
    currentPaymentFrequency = upcomingSubscription.paymentFrequency
  }

  return (
    <Grid container className={classes.sectionContainer}>
      <Grid item xs={4} md={6}><div className={classes.paymentInfo}>{ t('app:account.payment.title') }</div>
      </Grid>
      <Grid item className={classes.payment} xs={8} md={6}>
        { eCommerceIsDown
          ? <Typography>{ t('app:plan.unreachable.n/a') }</Typography>
          : (
            <>
              <Typography display="inline" className={onTrial ? classes.trialPaymentInfo : undefined}>
                <Amount value={price} currencyCode={user.currencyCode} />
              </Typography>
              <Typography display="inline" className={onTrial ? classes.trialPaymentInfo : undefined}>
                { t('app:plan.frequency.per_month') }
              </Typography>
            </>
            ) }
        { !eCommerceIsDown && onTrial && (
          <Typography display="inline">
            { ' ' + t('app:plans.free') }
          </Typography>
        ) }
        { !eCommerceIsDown && currentPaymentFrequency && (
          <Typography display="inline">
            { ' ' }
            ({ t(`app:plan.billing_frequency.${currentPaymentFrequency}`) })
          </Typography>
        ) }
      </Grid>
    </Grid>
  )
}

export default PaymentInfo
