import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@material-ui/core'

import Grid from '@material-ui/core/Grid'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { THEME_MODE_CONTEXT } from 'shared-module/ThemeContextProvider'
import ThemeIcon from 'vosker/src/assets/icons/ThemeIcon'
import CTypography from 'storybook-component-module/src/components/typographies/CTypography'
import Switch from 'react-ios-switch'
import { useHomePageFlag } from 'vosker/src/launchDarkly-module/useHomePageFlag'
import { Mixpanel } from 'vosker/src/Mixpanel'
import useUser from 'user-module/user/core/useUser'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  item: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
    paddingLeft: theme.spacing(4),
    '&:hover': {
      cursor: 'default',
      background: 'none',
    },
    '&:active': {
      background: 'none',
    },
  },
  title: {
    fontFamily: theme.typography.body1?.fontFamily,
    fontSize: theme.typography.body1?.fontSize,
    fontWeight: 600,
    lineHeight: '21px',
  },
  divider: {
    borderTop: `solid 1px ${theme.palette.secondary.main}`,
  },
  legacyItem: {
    paddingLeft: theme.spacing(4),
    '&:hover': {
      background: 'none',
    },
    '&:active': {
      background: 'none',
    },
  },
  legacyTitle: {
    fontFamily: theme.typography.body1.fontFamily,
  },
}))

const VoskerThemeMenuItem = ({ closeMenu, iconClass }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const user = useUser()
  const homePageEnabled = useHomePageFlag()

  const colorMode = useContext(THEME_MODE_CONTEXT)
  const [isChecked, setIsChecked] = useState(false)

  useEffect(() => {
    setIsChecked(colorMode.isDarkModeActive)
  }, [colorMode])

  const switchTheme = async (checked) => {
    await Mixpanel.track('Change Webapp Theme', { 'User Id': user.id, 'Uses Dark Mode': checked, 'Event Type': 'Theme Modified' })
    setIsChecked(checked)
    colorMode.toggleThemeMode()
    closeMenu()
  }

  return (
    <ListItem disableRipple button className={homePageEnabled ? classes.item : classes.legacyItem}>
      <ListItemIcon>
        <ThemeIcon fontSize="small" className={homePageEnabled ? iconClass : ''} />
      </ListItemIcon>

      <Grid container className={classes.container}>
        <Grid item>
          <CTypography variant="body1" className={homePageEnabled ? classes.title : classes.legacyTitle}>
            { t('menu.theme') }
          </CTypography>
        </Grid>

        <Grid item>
          <Switch
            checked={isChecked}
            onChange={switchTheme}
            offColor={homePageEnabled ? theme.palette.secondary.main : theme.palette.secondary.light}
            onColor={theme.palette?.status?.info}
            style={{ transform: 'scale(0.8)' }}
          />
        </Grid>
      </Grid>
    </ListItem>
  )
}

export default VoskerThemeMenuItem
