import photoApi from 'photo-module/photo/api/photoApi'

export const SET_FILTER_COLLECTION_ACTION = 'SET_FILTER_COLLECTION'
export const SET_SELECTED_FILTERS_ACTION = 'SET_SELECTED_FILTERS'
export const REMOVE_SELECTED_FILTERS_ACTION = 'REMOVE_SELECTED_FILTERS'
export const CLEAR_SELECTED_FILTERS_ACTION = 'CLEAR_SELECTED_FILTERS'
export const SET_TIME_PERIOD_ACTION = 'SET_TIME_PERIOD_ACTION'
export const CLEAR_TIME_PERIOD_ACTION = 'CLEAR_TIME_PERIOD_ACTION'

const setFilters = filters => ({ type: SET_FILTER_COLLECTION_ACTION, filters })
const setSelectedFilters = filter => ({ type: SET_SELECTED_FILTERS_ACTION, filter })
const removeSelectedFilters = filter => ({ type: REMOVE_SELECTED_FILTERS_ACTION, filter })
const clearSelectedFilters = () => ({ type: CLEAR_SELECTED_FILTERS_ACTION })
const setTimePeriod = action => ({ type: SET_TIME_PERIOD_ACTION, action })
const clearTimePeriod = () => ({ type: CLEAR_TIME_PERIOD_ACTION })

const fetchAllFilters = (camera) => dispatch =>
  photoApi.getAllFilters(camera)
    .then(filters => dispatch(setFilters(filters)))

const filtersActions = {
  fetchAllFilters,
  setFilters,
  setSelectedFilters,
  removeSelectedFilters,
  clearSelectedFilters,
  setTimePeriod,
  clearTimePeriod,
}
export default filtersActions
