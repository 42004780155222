import SvgIcon from '@material-ui/core/SvgIcon'

const ArrowLeft = (props) => {
  const { color, ...otherProps } = props

  return (
    <SvgIcon {...otherProps} width="29" height="29" viewBox="0 -1 27 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4026_34501)">
        <path d="M16 2.00003L17.7572 3.7573L9.52517 11.9894L17.7679 20.2321L16 22L7.11107 13.1111C6.81642 12.8165 6.65086 12.4168 6.65084 12C6.65082 11.5833 6.8164 11.1836 7.11107 10.8889L16 2.00003Z" fill={color} />
      </g>
      <defs>
        <clipPath id="clip0_4026_34501">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}
export default ArrowLeft
