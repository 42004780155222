import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import filtersActions from './filters.actions'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'

const useFilterCollection = () => {
  const dispatch = useDispatch()
  const camera = useSelectedCamera()

  const [isFetching, setIsFetching] = useState(false)
  const state = useSelector((state) => state.filterCollection)

  const fetchFilters = () => {
    if (camera && !isFetching) {
      setIsFetching(true)
      dispatch(filtersActions.fetchAllFilters(camera))
    }
  }

  return {
    filterCollection: state.filters,
    selectedFilters: state.selectedFilters,
    hasTimePeriod: state.hasTimePeriod,
    timePeriodValues: state.timePeriodValues,
    fetchFilters,
    addSelectedFilter: (filter) => dispatch(filtersActions.setSelectedFilters(filter)),
    deleteSelectedFilter: (filter) => dispatch(filtersActions.removeSelectedFilters(filter)),
    clearSelectedFilters: () => dispatch(filtersActions.clearSelectedFilters()),
    setFilters: (filters) => dispatch(filtersActions.setFilters(filters)),
    setTimePeriod: (hasTimePeriod, timePeriodValues) => dispatch(filtersActions.setTimePeriod({ hasTimePeriod, timePeriodValues })),
    clearTimePeriod: () => dispatch(filtersActions.clearTimePeriod()),
  }
}

export default useFilterCollection
