import downloadStatus from 'photo-module/download/downloadStatus'

const buildPhoto = (apiPhoto, camera = undefined) => ({
  id: apiPhoto.id,
  cameraId: apiPhoto.camera,
  date: apiPhoto.originDate,
  urls: {
    small: buildUrl(apiPhoto.small),
    medium: buildUrl(apiPhoto.medium),
    large: buildUrl(apiPhoto.large),
    hd: buildUrl(apiPhoto.hd),
    sdVideo: buildUrl(apiPhoto.sdVideo),
    hdVideo: buildUrl(apiPhoto.hdVideo),
    streamVideo: buildUrl(apiPhoto.streamVideo),
    preview: apiPhoto.preview?.map(buildUrl),
  },
  favorite: buildFavorite(apiPhoto.tag || ''),
  hdStatus: buildHdStatus(apiPhoto),
  sdVideoStatus: buildSdVideoStatus(apiPhoto, camera),
  hdVideoStatus: buildHdVideoStatus(apiPhoto, camera),
  hdVideoSize: apiPhoto.hdVideoSize,
  streamVideoDuration: buildVideoStreamDuration(apiPhoto.streamVideoDuration),
})

const buildFavorite = tag => tag.includes('fav')

const buildUrl = data => data?.host && data?.path ? `https://${data.host}/${data.path}` : null

const buildHdStatus = (apiPhoto) => {
  if (buildUrl(apiPhoto.hd)) return downloadStatus.available
  if (apiPhoto.hdPending) return downloadStatus.pending
  if (apiPhoto.hdFailed) return downloadStatus.error
  if (apiPhoto.hdUnavailable) return downloadStatus.unavailable

  return downloadStatus.requestable
}

const buildSdVideoStatus = (apiPhoto, camera) => {
  if (camera && !camera.videoCapability) return undefined
  if (buildUrl(apiPhoto.streamVideo)) return undefined
  if (buildUrl(apiPhoto.sdVideo)) return downloadStatus.available
  if (apiPhoto.sdVideoPending) return downloadStatus.pending
  if (apiPhoto.sdVideoFailed) return downloadStatus.error
  if (apiPhoto.sdVideoUnavailable) return downloadStatus.unavailable

  return downloadStatus.requestable
}

const buildHdVideoStatus = (apiPhoto, camera) => {
  if (camera && !camera.videoCapability) return undefined
  if (buildUrl(apiPhoto.streamVideo)) return undefined
  if (buildUrl(apiPhoto.hdVideo)) return downloadStatus.available
  if (apiPhoto.hdVideoPending) return downloadStatus.pending
  if (apiPhoto.hdVideoFailed) return downloadStatus.error
  if (apiPhoto.hdVideoUnavailable) return downloadStatus.unavailable

  return downloadStatus.requestable
}

const buildVideoStreamDuration = duration => {
  return typeof duration === 'number' ? Math.round(duration) : duration
}

export default buildPhoto
