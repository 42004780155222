import SvgIcon from '@material-ui/core/SvgIcon'

const ArrowRight = (props) => {
  const { color, ...otherProps } = props

  return (
    <SvgIcon {...otherProps} width="29" height="29" viewBox="0 -1 29 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4026_34498)">
        <path d="M8.00004 22L6.24277 20.2427L14.4748 12.0106L6.2321 3.7679L8.00004 1.99996L16.8889 10.8889C17.1836 11.1835 17.3491 11.5832 17.3492 12C17.3492 12.4167 17.1836 12.8164 16.8889 13.1111L8.00004 22Z" fill={color} />
      </g>
      <defs>
        <clipPath id="clip0_4026_34498">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>

  )
}
export default ArrowRight
