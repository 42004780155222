import { Stack } from '@mui/material'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 8px 8px',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '90vw',
    },
  },
  month: {
    fontWeight: 600,
    fontFamily: theme.typography.fontFamily,
    textTransform: 'capitalize',
  },
  button: {
    padding: 2,
    borderRadius: '0.375rem',
    '&:hover': {
      background: theme.palette.background?.empty,
      transition: 'opacity .3s',
    },
    '&.MuiIconButton-root.Mui-disabled': {
      opacity: 0.3,
    },
  },
}))

const CustomCalendarHeader = (props) => {
  const classes = useStyles()

  const { minDate, maxDate, currentMonth, onMonthChange } = props

  const selectNextYear = () => onMonthChange(currentMonth.add(1, 'year').subtract(11, 'month'), 'left')
  const selectPreviousYear = () => onMonthChange(currentMonth.subtract(1, 'year').add(11, 'month'), 'right')

  const selectNextMonth = () => currentMonth.$M === 12 ? selectNextYear() : onMonthChange(currentMonth.add(1, 'month'), 'left')
  const selectPreviousMonth = () => currentMonth.$M === 0 ? selectPreviousYear() : onMonthChange(currentMonth.subtract(1, 'month'), 'right')

  return (
    <Grid container className={classes.container} id="CustomCalendarHeader">
      <Stack spacing={1} direction="row">
        <IconButton disabled={minDate !== null && currentMonth.$M === minDate.$M && currentMonth.$y === minDate.$y} onClick={selectPreviousMonth} className={classes.button}>
          <ChevronLeft data-testid="ChevronLeft" />
        </IconButton>
      </Stack>

      <Grid item>
        <Typography variant="label" className={classes.month}>{ currentMonth.format('MMMM YYYY') }</Typography>
      </Grid>

      <Stack spacing={1} direction="row">
        <IconButton disabled={currentMonth.$M === maxDate.$M && currentMonth.$y === maxDate.$y} onClick={selectNextMonth} className={classes.button}>
          <ChevronRight data-testid="ChevronRight" />
        </IconButton>
      </Stack>
    </Grid>
  )
}

export default CustomCalendarHeader
