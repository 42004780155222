import { useMemo, useState } from 'react'

import { useTheme } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useECommerceFlag } from 'vosker/src/launchDarkly-module/useEcommerceFlag'
import { useCameraPlans } from '../core/cameraPlans.hook'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import PlanCard from 'vosker/src/camera-module/transmission-plan/ui/content/PlanCard'
import PlanHeader from 'vosker/src/camera-module/transmission-plan/ui/content/PlanHeader'
import GridLayout from 'vosker/src/camera-module/transmission-plan/ui/components/layout/GridLayout'
import { apiCameraPlans } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.types'
import PlanFrequencySwitch from 'vosker/src/camera-module/transmission-plan/ui/components/PlanFrequencySwitch'
import { showMonthlyByDefault } from 'vosker/src/camera-module/transmission-plan/core/discount/discount.selectors'
import { useECommerceProducts } from 'vosker/src/ecommerce-module/core/eCommerce.hook'
import paymentFrequency from 'vosker/src/order-module/order/core/paymentFrequency'

const VoskerPlansList = () => {
  const theme = useTheme()
  const camera = useSelectedCamera()
  const plans = useCameraPlans()
  const eCommerceProducts = useECommerceProducts()

  const eCommerceLdEnabled = useECommerceFlag()

  const legacyPlans = useMemo(() => {
    return !eCommerceLdEnabled
      ? plans?.plans.filter(plan => plan.id !== apiCameraPlans.empty)
      : []
  }, [eCommerceLdEnabled, plans])

  const count = eCommerceLdEnabled
    ? camera.subscription.plan.name === apiCameraPlans.free ? (eCommerceProducts?.plans?.length / 2) + 1 : eCommerceProducts?.plans?.length / 2
    : legacyPlans?.length

  const breakpoint = useMediaQuery(theme.breakpoints.only('xs'))
  const [displayMonthly, setDisplayMonthly] = useState(showMonthlyByDefault(camera))

  const renderHeaders = plan => {
    if (breakpoint && plan.name === apiCameraPlans.free) {
      return <PlanCard key={plan.id} camera={camera} plan={plan} />
    }
    return <PlanHeader key={plan.id} camera={camera} plan={plan} monthly={displayMonthly} count={count} />
  }

  const renderFreePlanHeader = () => {
    const currentPlan = camera.subscription.plan

    if (breakpoint) {
      return <PlanCard key={currentPlan.id} camera={camera} plan={currentPlan} />
    }

    return <PlanHeader key={currentPlan.id} camera={camera} plan={currentPlan} monthly={displayMonthly} count={count} />
  }

  return (
    <>
      <PlanFrequencySwitch displayMonthly={displayMonthly} setDisplayMonthly={setDisplayMonthly} />
      <GridLayout style={{ marginBottom: '1.5rem' }}>
        { eCommerceLdEnabled && camera.subscription.plan.name === apiCameraPlans.free && renderFreePlanHeader() }
        { eCommerceLdEnabled
          ? eCommerceProducts?.plans.map(plan =>
            displayMonthly
              ? plan.period === paymentFrequency.monthly && renderHeaders(plan)
              : plan.period === paymentFrequency.yearly && renderHeaders(plan))
          : legacyPlans?.map(plan => renderHeaders(plan)) }
      </GridLayout>
    </>
  )
}

export default VoskerPlansList
