import { stringify } from 'qs'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'

import webview from 'shared-module/webview/webview'
import messageActions from 'shared-module/message/messageActions'

import Price from 'vosker/src/camera-module/transmission-plan/ui/components/Price'
import paymentFrequency from 'vosker/src/order-module/order/core/paymentFrequency'
import AnnualPrice from 'vosker/src/camera-module/transmission-plan/ui/components/AnnualPrice'
import CurrentItem from 'vosker/src/camera-module/transmission-plan/ui/components/CurrentItem'
import BuyNowButton from 'vosker/src/camera-module/transmission-plan/ui/components/BuyNowButton'
import DiscountBanner from 'vosker/src/camera-module/transmission-plan/ui/components/DiscountBanner'
import { useDiscount } from 'vosker/src/camera-module/transmission-plan/core/discount/useDiscounts'
import { FREQUENCY } from 'vosker/src/camera-module/transmission-plan/core/discount/discount.types'
import { apiCameraPlans } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.types'
import { getPlanTranslationKey } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.selectors'
import { isCurrentPlan, onFreePlan, planIsUpgradeable, showCurrentPlan } from 'vosker/src/camera-module/transmission-plan/ui/helper/PlanHelper'
import { useBasket } from 'vosker/src/ecommerce-module/core/basket/BasketContext'
import useUser from 'user-module/user/core/useUser'
import eCommerceActions from 'vosker/src/ecommerce-module/core/eCommerce.actions'
import { useECommerceFlag } from 'vosker/src/launchDarkly-module/useEcommerceFlag'

const useStyles = makeStyles(theme => ({
  item: ({ count, showTrial }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    alignContent: 'space-between',
    margin: '0 4px',
    [theme.breakpoints.up('lg')]: {
      minWidth: count > 3 ? 200 : 230,
    },
    [theme.breakpoints.down('md')]: {
      minWidth: count > 3 ? 185 : 230,
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: count > 3 ? 135 : 165,
      margin: '0 2px',
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: 240,
      minWidth: count > 3 ? 70 : 90,
      marginTop: showTrial ? 26 : 0,
    },
  }),
  footNoteIndex: {
    fontSize: '0.5rem',
    fontWeight: 'bold',
    paddingLeft: '0.25rem',
  },
  titleContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: '0.375rem 0.75rem',
    backgroundColor: theme.palette.background.light,
  },
  title: {
    padding: 4,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
  },
  trialContainer: {
    width: '100%',
    display: 'inline-flex',
    borderRadius: '0.375rem 0.375rem 0rem 0rem',
    background: theme.palette.background.dark,
    color: theme.palette.text.contrastText,
  },
  trial: {
    padding: 4,
    width: '100%',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    fontSize: theme.typography.caption1?.fontSize,
    fontFamily: theme.typography.caption1?.fontFamily,
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.563rem',
    },
  },
  roundedEdge: {
    borderRadius: '0.375rem 0.375rem 0rem 0rem',
  },
  descriptionContainer: ({ isFree, monthly }) => ({
    minHeight: monthly ? '10.5rem' : '13.75rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: isFree ? 'flex-start' : 'space-around',
    alignItems: 'center',
    padding: !isFree ? '1rem 0rem 0rem' : monthly ? '1.375rem 0rem 0rem' : '3.375rem 0rem 0rem',
    background: theme.palette.background.paper,
    borderRadius: '0rem 0rem 0.375rem 0.375rem',
  }),
  currentPlan: {
    marginTop: '0.75rem',
  },
}))

const PlanHeader = ({ camera, plan, monthly, count }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const user = useUser()
  const eCommerceLdEnabled = useECommerceFlag()

  const showTrial = plan.name === apiCameraPlans.elite
  const classes = useStyles({ count, showTrial, monthly, isFree: plan.isFree })

  const discount = useDiscount(plan, monthly ? FREQUENCY.monthly : FREQUENCY.yearly)
  const isOnTrial = camera && !camera.isCompletingTrial && camera?.subscription.isFree && !camera?.subscription.plan.isFree

  const isCurrentDisplayedPlan = eCommerceLdEnabled ? onFreePlan(camera) ? isCurrentPlan(camera, plan, eCommerceLdEnabled) : showCurrentPlan(camera, plan, monthly, eCommerceLdEnabled) : showCurrentPlan(camera, plan, monthly, eCommerceLdEnabled)
  const buyButtonDisabled = !onFreePlan(camera) && !planIsUpgradeable(camera, plan, monthly, eCommerceLdEnabled)
  const label = !buyButtonDisabled && !onFreePlan(camera) && t('app:plan.buttons.upgrade')

  const getPrice = () => {
    if (eCommerceLdEnabled) {
      if (plan.name !== apiCameraPlans.free) {
        return monthly ? plan?.price : plan?.price / 12
      } else {
        return 0
      }
    } else {
      return plan.isFree ? 0 : monthly ? plan.pricePerMonthIfPaidPerMonth : plan.pricePerMonthIfPaidAnnually
    }
  }

  const price = getPrice()
  const annualPrice = eCommerceLdEnabled && plan.name !== apiCameraPlans.free
    ? plan?.price
    : plan?.pricePerYear

  const { addProductToBasket } = useBasket()

  const newCartItem = [{
    productId: plan?.id,
    quantity: plan?.stepQuantity,
    cameraId: camera.id,
    cameraName: camera.config.name,
    price: plan?.price,
    productName: plan?.name,
    itemText: plan?.primaryCategoryId,
  }]

  const onBuyOldPurchaseFlow = () => {
    const frequency = monthly ? paymentFrequency.monthly : paymentFrequency.yearly
    const products = { p: [{ id: 'CameraPlan', planId: plan.id, frequency, cameraId: camera.id }] }
    const params = stringify(products, { encode: false })

    if (webview.isWebview()) {
      webview.postMessage({
        action: 'navigate',
        to: 'checkout',
        options: {
          cameraId: camera.id,
          planId: plan.id,
          frequency: frequency,
        },
      })
      return
    }

    history.push(`/order?${params}`)
  }

  const onBuyNowClick = async () => {
    try {
      if (eCommerceLdEnabled) {
        await dispatch(eCommerceActions.proceedToPayment(camera, user, addProductToBasket, newCartItem))
      } else {
        onBuyOldPurchaseFlow()
      }
    } catch (error) {
      dispatch(messageActions.showError(error))
    }
  }

  return (
    <Grid container item xs sm md={2} className={classes.item}>
      { showTrial && isOnTrial &&
        (
          <Grid item className={classes.trialContainer} data-testid="banner">
            <Typography className={classes.trial} align="center">
              { t(`app:plan.trial.title.${camera.subscription.paymentFrequency}`).toUpperCase() }
              { camera.status?.capability?.stream && <Typography className={classes.footNoteIndex}>4</Typography> }
            </Typography>
          </Grid>
        ) }

      <Grid item className={clsx(classes.titleContainer, !showTrial && classes.roundedEdge)}>
        <Typography variant="h4" className={classes.title}>{ t(`app:plans.${getPlanTranslationKey(plan.name)}`) }</Typography>
      </Grid>

      <Grid container item className={classes.descriptionContainer}>
        { (discount || plan?.discount) && <DiscountBanner discount={eCommerceLdEnabled ? plan?.discount : discount} /> }

        <Grid item>
          <Price price={price} annualPrice={monthly ? undefined : plan.pricePerYear} monthly isPlan />
        </Grid>

        { isCurrentDisplayedPlan && !isOnTrial && (
          <Grid item className={plan.isFree ? classes.currentPlan : ''}>
            <CurrentItem />
          </Grid>
        ) }

        { !plan.isFree && (!isCurrentDisplayedPlan || isOnTrial) && (
          <BuyNowButton disabled={buyButtonDisabled} onClick={onBuyNowClick} label={label} plan={plan} />) }

        { !(price === 0) && !monthly && <AnnualPrice annualPrice={annualPrice} /> }
      </Grid>
    </Grid>
  )
}

export default PlanHeader
