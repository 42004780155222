import { getCellularSignalIcon } from 'camera-module/camera/core/getCellularSignalIcon'
import Box from '@mui/material/Box'

const CellularSignalIcon = ({ bar, isNewUi, viewBox = '0 0 32 32' }) => {
  const Icon = getCellularSignalIcon(bar)

  return isNewUi
    ? (
      <Box pl={0.325} display="flex" justifyContent="center">
        <svg width="32" height="32" viewBox={viewBox}>
          <Icon />
        </svg>
      </Box>
      )
    : (
      <div>
        <Icon />
      </div>
      )
}

export default CellularSignalIcon
