import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@mui/material'

const AccountIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon data-testid="AccountIcon" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2C0 0.895431 0.895431 0 2 0H18C19.1046 0 20 0.895431 20 2V18C20 19.1046 19.1046 20 18 20H2C0.895431 20 0 19.1046 0 18V2ZM5.00003 13.5001C5 12.3955 5.89544 11.5 7.00003 11.5H13C14.1046 11.5 15 12.3954 15 13.5L15 14.3126H5.00004L5.00003 13.5001ZM9.77776 5.50007C8.79592 5.50007 7.99998 6.296 7.99998 7.27784V7.72229C7.99998 8.70413 8.79592 9.50007 9.77776 9.50007H10.2222C11.204 9.50007 12 8.70413 12 7.72229V7.27784C12 6.29601 11.204 5.50007 10.2222 5.50007H9.77776Z"
        fill={`${theme.palette.text.primary} !important`}
      />
    </SvgIcon>
  )
}

export default AccountIcon
