import { combineReducers } from 'redux'
import userPaymentHistoryState from 'shared-module/user/userPaymentHistory.state'

import userReducer from 'user-module/user/core/userReducer'
import cameraReducer from 'camera-module/camera/core/cameraReducer'
import cameraModelsReducer from 'camera-module/model/core/cameraModelsReducer'
import freeTrialActivationModalReducer from 'camera-module/free-trial-activation-modal/core/freeTrialActivationModalReducer'
import messageReducer from 'shared-module/message/messageReducer'
import dialogReducer from 'shared-module/dialog/dialogReducer'
import photoState from 'photo-module/photo/core/photoState'
import filtersState from 'photo-module/filters/core/filtersState'
import galleryPhotos from 'photo-module/photos/core/galleryPhotos'
import appResetState from 'shared-module/app-reset/appResetState'
import camerasState from 'camera-module/cameras/core/camerasState'
import loadingState from 'shared-module/loading/loadingState'
import userPaymentInformationState from 'vosker/src/user-module/payment-information/core/userPaymentInformationState'
import { liveStreamingReducer } from 'video-module/live-streaming'
import cameraPlansReducer from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.reducer'
import userPaymentMethodsState from 'shared-module/user/userPaymentMethods.state'
import NotificationsReducer from 'notifications-module/core/notifications.reducer'
import detectionZoneReducer from 'detection-zone-module/core/detectionZone.reducer'
import filterCollectionReducer from 'photo-module/filters-bar/core/filters.reducer'
import eCommerceReducer from 'vosker/src/ecommerce-module/core/eCommerce.reducer'

const appReducer = combineReducers({
  // user
  user: userReducer,
  paymentInformation: userPaymentInformationState.reducer,

  // camera
  cameras: camerasState?.reducer,
  cameraPlans: cameraPlansReducer,
  eCommerceProducts: eCommerceReducer,
  selectedCamera: cameraReducer,
  models: cameraModelsReducer,
  freeTrialActivationModal: freeTrialActivationModalReducer,
  detectionZone: detectionZoneReducer,

  // photos
  galleryPhoto: galleryPhotos.reducer,
  photo: photoState.reducer,
  filters: filtersState.reducer,
  filterCollection: filterCollectionReducer,

  // video
  liveStreaming: liveStreamingReducer,

  // shared
  message: messageReducer,
  error: dialogReducer,
  loading: loadingState.reducer,

  // payment methods
  paymentMethods: userPaymentMethodsState.reducer,

  // payment history
  paymentHistory: userPaymentHistoryState.reducer,

  // notifications
  notifications: NotificationsReducer,
})

const reducer = appResetState.reducer(appReducer)

const appState = { reducer: reducer }
export default appState
