import { useTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { Typography } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'

import InfoIcon from 'vosker/src/assets/icons/InfoIcon'

const useStyles = makeStyles(theme => ({
  container: {
    padding: '2rem 20px',
    [theme.breakpoints.down('sm')]: {
      padding: '1.5rem 0 2rem',
    },
  },
  paper: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '2rem',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      padding: '1rem',
    },
  },
  icon: {
    marginTop: '0.25rem',
    marginRight: '-0.25rem',
  },
  title: {
    padding: 12,
    textAlign: 'left',
    lineHeight: '1rem',
    textTransform: 'none',
    fontWeight: 600,
  },
  message: {
    whiteSpace: 'pre-line',
    lineHeight: '21px',
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'normal',
    },
  },
}))

const PlansUnreachableTile = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Paper className={classes.paper} elevation={0}>
        <Grid container>
          <Grid container item alignItems="center">
            <Grid item>
              <InfoIcon className={classes.icon} />
            </Grid>

            <Grid item>
              <Typography variant="h4" className={classes.title}>{ t('app:plan.unreachable.title') }</Typography>
            </Grid>
          </Grid>

          <Grid item>
            <Typography variant="label" className={classes.message}>{ t('app:plan.unreachable.message') }</Typography>
          </Grid>
        </Grid>

      </Paper>
    </Container>
  )
}

export default PlansUnreachableTile
