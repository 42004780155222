import { useTheme } from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'

const HomeIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon data-testid="HomeIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8857 9.48627C20.3174 9.81002 20.5714 10.3181 20.5714 10.8576V18.9999C20.5714 19.9467 19.8039 20.7142 18.8571 20.7142H5.14282C4.19605 20.7142 3.42853 19.9467 3.42854 18.9999L3.42855 10.8576C3.42855 10.3181 3.68258 9.81002 4.1142 9.48627L10.9714 4.3429C11.5809 3.88569 12.4191 3.88569 13.0286 4.3429L19.8857 9.48627ZM13.5 12.7856H10.5V15.7856H13.5V12.7856Z"
        fill={theme.palette.text.primary}
      />
    </SvgIcon>
  )
}

export default HomeIcon
