import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import App from 'shared-module/App'
import 'shared-module/monitoring/apm'
import theme from 'vosker/src/shared-module/theme/theme'
import lightTheme from 'vosker/src/shared-module/theme/lightTheme'
import 'vosker/src/assets/locales/i18n.js'
import 'vosker/src/assets/fonts/fonts.css'
import Routes from 'vosker/src/Routes'
import userActions from 'vosker/src/user-module/user/core/userActions'
import photoActions from 'vosker/src/photos-module/photo/core/photoActions'
import cameraActions from 'vosker/src/camera-module/camera/core/cameraActions'
import packageInfo from '../package.json'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import { getClientSideId } from 'vosker/src/setLaunchDarklyEnv'

const actions = { userActions: userActions, photoActions: photoActions, cameraActions: cameraActions }
const params = new URLSearchParams(window.location.search)
const themeFromUrl = params.get('theme')
const LDProvider = withLDProvider({
  clientSideID: getClientSideId(),
  context: {
    kind: 'user',
    anonymous: true,
    key: 'user-key-123abc',
  },
})(App)

// Google Analytics ID
export const TRACKING_ID = {
  LOCAL_TRACKING_ID: 'UA-11325817-9',
  PROD_TRACKING_ID: 'UA-11325817-8',
}

/*
 * GTM: Google Analytics Configuration.
 * To test locally, replace current ID with the LOCAL_TRACKING_ID
 */
ReactGA.initialize(TRACKING_ID.PROD_TRACKING_ID)

ReactDOM.render(
  <LDProvider darkTheme={theme} lightTheme={lightTheme} currentTheme={themeFromUrl} actions={actions} version={packageInfo.version} supportNewUi>
    <Routes />
  </LDProvider>, document.getElementById('root'),
)
