import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import AddIcon from '@material-ui/icons/Add'
import { makeStyles } from '@material-ui/core/styles'

import AddCameraIcon from 'camera-module/cameras/ui/cameras-page/icons/AddCameraIcon'
import VoskerSecondaryButton from 'vosker/src/components/buttons/VoskerSecondaryButton'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    whiteSpace: 'nowrap',
    width: 'auto',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  item: {
    display: 'contents',
  },
  addButton: {
    width: 'auto',
    height: '2.5rem',
    [theme.breakpoints.down('xs')]: {
      margin: '0.75rem 0.5rem 0 0.5rem',
    },
  },
  icon: {
    fontSize: 24,
  },
}))

const ActivateCameraButton = ({ toggleAddCamera, displayFullWidth }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box component={Grid} container className={classes.container}>
      <Grid item className={classes.item} sm md={6} lg={4}>
        <VoskerSecondaryButton
          onClick={toggleAddCamera}
          className={classes.addButton}
          icon={displayFullWidth && <AddIcon data-testid="AddCameraIcon" className={classes.icon} />}
        >
          { displayFullWidth ? t('app:active_device.activate_camera') : <AddCameraIcon /> }
        </VoskerSecondaryButton>
      </Grid>
    </Box>
  )
}

export default ActivateCameraButton
