import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import { useTheme } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { makeStyles } from '@material-ui/core/styles'

import AddCameraIcon from 'camera-module/cameras/ui/cameras-page/icons/AddCameraIcon'
import CButtonIcon from 'storybook-component-module/src/components/buttons/CButtonIcon'
import CTypography from 'storybook-component-module/src/components/typographies/CTypography'
import { useHomePageFlag } from 'vosker/src/launchDarkly-module/useHomePageFlag'

const useStyles = makeStyles((theme) => ({
  wrapper: ({ isNewUi, homePageEnabled }) => ({
    width: homePageEnabled ? (isNewUi ? '253px !important' : '250px') : !isNewUi && '200px',
    minWidth: homePageEnabled ? (isNewUi ? '255px' : 256) : isNewUi && 256,
    height: homePageEnabled ? (isNewUi ? 134 : '300px') : isNewUi ? 350 : '253px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: homePageEnabled
      ? (isNewUi ? '2.70rem 0.55rem' : '8px')
      : (isNewUi ? '0.75rem 0.5rem' : '10px'),
    border: isNewUi && '1px solid' + theme.palette.secondary.main,
    borderRadius: isNewUi && '0.375rem',
    background: theme.palette.background.default,
    '&:hover': {
      cursor: 'pointer',
      opacity: isNewUi && 1,
      filter: isNewUi && 'brightness(102%)',
      background: isNewUi ? theme.palette.background.default : theme.palette.background?.tile,
    },
    [theme.breakpoints.only('md')]: {
      minWidth: isNewUi && 224,
    },
    [theme.breakpoints.only('sm')]: {
      minWidth: isNewUi && 218,
    },
  }),
  addButtonIcon: ({ isNewUi }) => ({
    padding: '0.5rem',
    marginBottom: isNewUi ? '0rem' : '0.75rem',
    color: isNewUi ? 'none' : theme.palette.background.default,
    background: isNewUi ? 'none' : theme.palette.primary.main,
    '&:hover': {
      color: isNewUi ? 'none' : theme.palette.background.default,
      background: isNewUi ? 'none' : theme.palette.primary.main,
    },
  }),
  icon: {
    width: 48,
    height: 48,
  },
}))

const ActivateCameraTile = ({ toggleAddCamera, displayText = true, isNewUi = false, isSpypoint }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const homePageEnabled = useHomePageFlag()
  const classes = useStyles({ isNewUi, homePageEnabled })

  return isNewUi
    ? (
      <Box container item xs sm={3} md={1} lg={2} xl={1} onClick={toggleAddCamera} className={classes.wrapper}>
        <CButtonIcon className={classes.addButtonIcon}>
          <AddCameraIcon className={classes.icon} isSpypoint={isSpypoint} color={isSpypoint ? theme.palette.primary.main : theme.palette.secondary.main} />
        </CButtonIcon>

        { displayText && <CTypography variant="h3">{ t('app:active_device.activate_camera') }</CTypography> }
      </Box>
      )
    : (
      <Box className={classes.wrapper} onClick={toggleAddCamera}>
        <CButtonIcon size="medium" className={classes.addButtonIcon}>
          <AddIcon />
        </CButtonIcon>

        <CTypography variant="h4">{ t('app:active_device.activate_camera') }</CTypography>
      </Box>
      )
}
export default ActivateCameraTile
