import NewSignalZero from 'assets/icons/status/new-status-icons/NewSignalZero'
import NewSignalOne from 'assets/icons/status/new-status-icons/NewSignalOne'
import NewSignalTwo from 'assets/icons/status/new-status-icons/NewSignalTwo'
import NewSignalThree from 'assets/icons/status/new-status-icons/NewSignalThree'
import NewSignalFour from 'assets/icons/status/new-status-icons/NewSignalFour'
import NewSignalFive from 'assets/icons/status/new-status-icons/NewSignalFive'

export const getCellularSignalIcon = (bar) => {
  switch (bar) {
    case 0: return NewSignalZero
    case 1: return NewSignalOne
    case 2: return NewSignalTwo
    case 3: return NewSignalThree
    case 4: return NewSignalFour
    case 5: return NewSignalFive
    default: return NewSignalZero
  }
}
