import { useTheme } from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'

const HomeStatusIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon data-testid="HomeStatusIcon" width="16" height="16" viewBox={props.viewBox || '0 0 16 16'} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.98498 14.6667C11.6751 14.6667 14.6666 11.6819 14.6666 8.00004C14.6666 4.31814 11.6751 1.33337 7.98498 1.33337C4.50961 1.33337 1.65397 3.98081 1.33333 7.36469H5.07033L6.74972 4.43233L8.97692 8.87678L9.8429 7.36469H12.1212V8.63453H10.5815L8.90208 11.5669L6.67488 7.12245L5.8089 8.63453H1.33325C1.65349 12.0188 4.50932 14.6667 7.98498 14.6667Z"
        fill={theme.palette.text.primary}
      />
    </SvgIcon>

  )
}

export default HomeStatusIcon
