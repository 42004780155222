import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const TimePeriodIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon data-testid="TimePeriodIcon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M7.49996 10.9333V9.68332H5.83329V10.9333H7.49996Z" fill={theme.palette.text.primary} />
      <path d="M7.49996 12.15V13.4H5.83329V12.15H7.49996Z" fill={theme.palette.text.primary} />
      <path d="M7.49996 15.8667V14.6167H5.83329V15.8667H7.49996Z" fill={theme.palette.text.primary} />
      <path d="M9.16663 9.68332H10.8333V10.9333H9.16663V9.68332Z" fill={theme.palette.text.primary} />
      <path d="M10.8333 12.15H9.16663V13.4H10.8333V12.15Z" fill={theme.palette.text.primary} />
      <path d="M9.16663 14.6167H10.8333V15.8667H9.16663V14.6167Z" fill={theme.palette.text.primary} />
      <path d="M14.1666 10.9333V9.68332H12.5V10.9333H14.1666Z" fill={theme.palette.text.primary} />
      <path d="M14.1666 12.15V13.4H12.5V12.15H14.1666Z" fill={theme.palette.text.primary} />
      <path d="M14.1666 15.8667V14.6167H12.5V15.8667H14.1666Z" fill={theme.palette.text.primary} />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1666 4.4444H5.83329V1.66663H4.4444V4.4444H2.36107C1.97754 4.4444 1.66663 4.75532 1.66663 5.13885V17.6388C1.66663 18.0224 1.97754 18.3333 2.36107 18.3333H17.6388C18.0224 18.3333 18.3333 18.0224 18.3333 17.6388V5.13885C18.3333 4.75532 18.0224 4.4444 17.6388 4.4444H15.5555V1.66663H14.1666V4.4444ZM3.05551 16.9444V8.61107H16.9444V16.9444H3.05551Z"
        fill={theme.palette.text.primary}
      />
    </SvgIcon>
  )
}

export default TimePeriodIcon
