import { useTheme } from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'

const HomeIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon
      data-testid="HomeIcon"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M19.8857 9.48628C20.3174 9.81003 20.5714 10.3181 20.5714 10.8577V18.9999C20.5714 19.9467 19.8039 20.7142 18.8571 20.7142H5.14281C4.19604 20.7142 3.42853 19.9467 3.42853 18.9999L3.42854 10.8577C3.42855 10.3181 3.68257 9.81003 4.11419 9.48628L10.9713 4.34291C11.5809 3.8857 12.4191 3.8857 13.0286 4.34291L19.8857 9.48628ZM13.5 12.7857H10.5V15.7857H13.5V12.7857Z" fill={theme.palette.text.primary} />

    </SvgIcon>
  )
}

export default HomeIcon
