import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Chip from '@material-ui/core/Chip'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'

import useUser from 'user-module/user/core/useUser'
import LiveStreamIcon from 'assets/icons/navigation/LiveStreamIcon'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import LinkMenuItem from 'shared-module/navigation/menu/items/LinkMenuItem'
import { apiCameraPlans } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.types'
import { useHomePageFlag } from 'vosker/src/launchDarkly-module/useHomePageFlag'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  secondaryAction: {
    position: 'relative',
    left: '75%',
    transform: 'translateY(-145%)',
    marginBottom: -16,
  },
}))

const LiveStreamMenuItem = ({ onClose }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const camera = useSelectedCamera()
  const user = useUser()
  const homePageEnabled = useHomePageFlag()

  const goToPlans = () => {
    history.push(`/camera/${camera.id}/plan`)
    onClose()
  }

  const goToAddons = () => {
    history.push(`/camera/${camera.id}/plan#addons`)
    onClose()
  }

  if (!camera.stream.available) {
    return null
  }

  const hasPlan = camera && camera.subscription.plan.id !== apiCameraPlans.empty
  const hasStreamingTime = user?.addOns?.sdStreamingSeconds > 0

  return (
    <>
      <LinkMenuItem id="LiveStreaming" Icon={LiveStreamIcon} text="menu.livestream" path={`/camera/${camera.id}/live-streaming`} onClick={onClose} />
      { !homePageEnabled && (
        <ListItemSecondaryAction className={classes.secondaryAction}>
          { !hasPlan && (
            <Chip
              size="small"
              color="primary"
              onClick={goToPlans}
              label={t('menu.livestream_chip_no_plan')}
              style={{ cursor: 'pointer', fontWeight: '600', fontSize: '9px' }}
            />
          ) }

          { hasPlan && !hasStreamingTime && (
            <Chip
              size="small"
              color="primary"
              onClick={goToAddons}
              label={t('menu.livestream_chip_no_time')}
              style={{ cursor: 'pointer', fontWeight: '600', fontSize: '9px' }}
            />
          ) }
        </ListItemSecondaryAction>
      ) }
    </>
  )
}

export default LiveStreamMenuItem
