import usePhotos from 'photo-module/photos/core/usePhotos'
import { useHistory } from 'react-router-dom'
import usePhotoActions from 'photo-module/photo/core/usePhotoActions'

const PHOTOS_REMAINING_BEFORE_RELOAD = 5

const getCurrentPhotoIndex = (photos, photoId) => {
  if (!photos || photos.length === 0) {
    return null
  }

  return photos.findIndex(element => element.id === photoId)
}

const getPreviousPhoto = (photos, currentPhotoIndex) => {
  if (currentPhotoIndex === -1 || currentPhotoIndex === 0) {
    return null
  }

  return photos[currentPhotoIndex - 1]
}

const getNextPhoto = (photos, currentPhotoIndex) => {
  const lastIndex = photos.length - 1
  if (currentPhotoIndex === -1 || currentPhotoIndex === lastIndex) {
    return null
  }

  return photos[currentPhotoIndex + 1]
}

const reloadGalleryEnd = (photos, currentPhotoIndex, allPhotosLoaded) => {
  const lastIndex = photos.length - 1
  const isArrayEmpty = photos.length === 0
  const currentPhotoIndexHitsTheEnd = currentPhotoIndex >= lastIndex - PHOTOS_REMAINING_BEFORE_RELOAD

  return !allPhotosLoaded && !isArrayEmpty && currentPhotoIndexHitsTheEnd
}

const usePhotoViewerNavigation = (photoId) => {
  const history = useHistory()
  const currentFromPage = history.location.state?.fromPage
  const nextState = currentFromPage === 'photoGallery' ? { fromPage: 'photoGallery' } : undefined

  const photoGallery = usePhotos()
  const photos = photoGallery.photos
  const photoAction = usePhotoActions()
  const currentPhotoIndex = getCurrentPhotoIndex(photos, photoId)

  const switchPhoto = photo => {
    photoAction.set(photo)
    history.push(`/camera/${photo.cameraId}/photo/${photo.id}`, nextState)
  }

  return {
    previousPhoto: getPreviousPhoto(photos, currentPhotoIndex),
    nextPhoto: getNextPhoto(photos, currentPhotoIndex),
    reloadGalleryEnd: reloadGalleryEnd(photos, currentPhotoIndex, photoGallery.allPhotosLoaded),
    switchPhoto,
  }
}

export default usePhotoViewerNavigation
