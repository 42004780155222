import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { useEffect, useRef, useState } from 'react'

import { useTheme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Menu from '@material-ui/core/Menu'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import Collapse from '@material-ui/core/Collapse'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import ExpandedIcon from 'assets/icons/photo/ExpandedIcon'
import ExpansionIcon from 'assets/icons/photo/ExpansionIcon'
import useFilterCollection from 'photo-module/filters-bar/core/filters.hooks'
import { getSelectedFilterCount } from 'photo-module/filters-bar/core/filters.selectors'
import FilterLabel from 'vosker/src/photos-module/photos-gallery/ui/filters-bar/FilterLabel'
import TimePeriodFilter from 'vosker/src/photos-module/photos-gallery/ui/filters-bar/TimePeriodFilter'
import { useMediaTypeFilter } from 'vosker/src/launchDarkly-module/useMediaTypeFilter'

import dayjs from 'dayjs'

require('dayjs/locale/de')
require('dayjs/locale/fr')
require('dayjs/locale/es')
require('dayjs/locale/en')

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: -4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      marginLeft: -20,
      width: '100%',
      marginBottom: '0px',
    },
  },
  resizedBox: ({ listWidth }) => ({
    width: listWidth,
  }),
  box: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'max-content',
    margin: '4px 10px 4px 4px',
    borderRadius: '0.375rem',
    padding: '5px 5px 5px 10px',
    maxHeight: '2.5rem',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('xs')]: {
      marginRight: -8,
      marginTop: 0,
      marginBottom: 0,
      maxHeight: 'inherit',
      width: '100%',
      justifyContent: 'space-between',
      paddingRight: 10,
      padding: '4px 12px',
    },
  },
  timePeriodBox: {
    width: 304,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  boxRadiusWhileOpen: {
    borderRadius: '0.375rem 0.375rem 0 0',
  },
  number: {
    width: 12,
    marginRight: '1rem',
    color: theme.palette.text?.primary,
    fontWeight: 600 + ' !important',
  },
  divider: {
    marginLeft: 8,
    marginRight: 8,
    marginBottom: '0px !important',
    marginTop: '0px !important',
    color: theme.palette.border?.divider,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 16,
      width: '100%',
      height: '1px',
      backgroundColor: theme.palette.border?.divider,
    },
  },
  expansion: {
    marginTop: 16,
    marginLeft: 10,
    [theme.breakpoints.down('xs')]: {
      marginRight: -20,
    },
  },
  collapse: {
    marginLeft: 16,
    marginRight: -32,
  },
  timePeriodCollapse: {
    marginLeft: 0,
    marginRight: -32,
  },
  title: {
    paddingRight: '0.375rem',
    fontWeight: 600 + ' !important',
    fontSize: theme.typography.body2?.fontSize,
    fontFamily: theme.typography.body2?.fontFamily,
  },
  elevation: {
    '& .MuiPaper-elevation0': {
      boxShadow: `0px 16px 16px 0px ${theme.palette.background?.paperShadow}`,
    },
    '& .MuiPaper-rounded': {
      borderRadius: '0 0 6px 6px',
    },
  },
}))

const FilterCategory = ({ Icon, category, label, values, camera, setShowButtonSection = {} }) => {
  const theme = useTheme()
  const { i18n, t } = useTranslation()
  const breakpoint = useMediaQuery(theme.breakpoints.down('xs'))
  const { selectedFilters, hasTimePeriod } = useFilterCollection()

  const menuListRef = useRef()
  const [checked] = useState(values && Array.from({ length: values.length }, (_, index) => false))
  const [open, setOpen] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [menuAnchor, setMenuAnchor] = useState(null)
  const [anchorWidth, setAnchorWidth] = useState(0)
  const [filterCount, setFilterCount] = useState(0)
  const [timePeriodLabel, setTimePeriodLabel] = useState('')
  const [listWidth, setListWidth] = useState('max-content')
  const [menuTacksListWidth, setMenuTacksListWidth] = useState(false)

  const classes = useStyles({ listWidth })
  const mediaTypeEnabled = useMediaTypeFilter()
  const hasMultiplePowerSources = camera?.status?.powerSources?.length > 0

  const VEHICLE_ORDER = [
    'vehicle',
    'civilvehicle',
    'fleetvehicle',
    'machineryvehicle',
    'offroadvehicle',
    'othervehicles',
  ]

  const MEDIA_TYPE_ORDER = [
    'photo',
    'hdphoto',
    'preview',
    'hdvideo',
    'streamvideo',
  ]

  const sortByOrder = (values, orderArray) => {
    return values.sort((a, b) => {
      const indexA = orderArray.indexOf(a)
      const indexB = orderArray.indexOf(b)
      return (indexA !== -1 ? indexA : orderArray.length) -
             (indexB !== -1 ? indexB : orderArray.length)
    })
  }
  const filteredValues = !values
    ? null
    : category === 'mediaTypes' && mediaTypeEnabled
      ? sortByOrder(values, MEDIA_TYPE_ORDER)
      : category === 'vehicles'
        ? sortByOrder(values, VEHICLE_ORDER)
        : values

  // eslint-disable-next-line
  useEffect(() => {
    menuAnchor && setAnchorWidth(menuAnchor.offsetWidth)
    !breakpoint && setOpen(false)
  })

  useEffect(() => {
    if (menuListRef.current && filteredValues !== null) {
      if (menuListRef.current.offsetWidth > anchorWidth) {
        setMenuTacksListWidth(true)
        setListWidth(menuListRef.current.offsetWidth)
      } else {
        setListWidth(anchorWidth)
      }
    }

    dayjs.locale(i18n.language)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, anchorWidth])

  useEffect(() => {
    values && setFilterCount(getSelectedFilterCount(values, selectedFilters))
  }, [values, selectedFilters])

  useEffect(() => {
    if (!hasTimePeriod) {
      setTimePeriodLabel('')
    }
  }, [hasTimePeriod])

  const openMenu = (e) => {
    setOpen(true)
    setExpanded(true)
    setMenuAnchor(e.currentTarget)
    setAnchorWidth(e.currentTarget.offsetWidth)
    filteredValues === null && breakpoint && setShowButtonSection(false)
  }

  const closeMenu = () => {
    setMenuAnchor(null)
    setOpen(false)
    setExpanded(false)
    setMenuTacksListWidth(false)
    filteredValues === null && breakpoint && setShowButtonSection(true)
  }

  const renderChildren = filteredValues === null
    ? <TimePeriodFilter setTimePeriodLabel={setTimePeriodLabel} onClose={closeMenu} />
    : filteredValues && filteredValues.map((value, index) => {
      const isLatestItem = filteredValues.length === index + 1

      return (
        <div key={value} id={label}>
          <FilterLabel category={category} label={value} checked={checked[index]} style={{ padding: breakpoint ? '6px 16px' : '5px 5px 5px 12px' }} />
          { !isLatestItem && !breakpoint && <Divider className={classes.divider} /> }
        </div>
      )
    })

  const categoryBoxClasses =
    filteredValues === null
      ? expanded ? clsx(classes.box, classes.timePeriodBox, classes.boxRadiusWhileOpen) : classes.box
      : menuTacksListWidth
        ? expanded && clsx(classes.box, classes.resizedBox, classes.boxRadiusWhileOpen)
        : expanded ? clsx(classes.box, classes.boxRadiusWhileOpen) : classes.box

  return (
    <Box data-testid={label} className={classes.root}>
      <Box className={categoryBoxClasses} onClick={open ? closeMenu : openMenu}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Icon style={{ marginBottom: 2, marginRight: 8 }} />

          { filteredValues === null && (
            <Typography className={classes.title} mb={0} noWrap>
              { timePeriodLabel !== '' ? timePeriodLabel : t('app:filters.tags.' + label) }
            </Typography>
          ) }

          { filteredValues && (
            <Typography className={classes.title} mb={0} noWrap>
              { t('app:filters.tags.' + label) }
            </Typography>
          ) }

          { filterCount !== 0 && (
            <Typography variant="body2" className={classes.number}>
              ({ filterCount })
            </Typography>
          ) }
        </Box>

        { expanded
          ? <ExpandedIcon color={theme.palette.text.primary} className={classes.expansion} />
          : <ExpansionIcon color={theme.palette.text.primary} className={classes.expansion} /> }
      </Box>

      { !breakpoint
        ? (
          <Menu
            elevation={0}
            open={!!menuAnchor}
            onClose={closeMenu}
            style={{ top: 0 }}
            anchorEl={menuAnchor}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            className={classes.elevation}
            MenuListProps={{
              ref: menuListRef,
              disablePadding: true,
              autoFocus: false,
              autoFocusItem: false,
              style: { width: filteredValues === null ? anchorWidth : listWidth },
            }}
          >
            { renderChildren }
          </Menu>
          )
        : (
          <Collapse in={open} className={filteredValues === null ? classes.timePeriodCollapse : classes.collapse}>
            <List disablePadding>{ renderChildren }</List>
          </Collapse>
          ) }
      { breakpoint && <Divider className={classes.divider} style={{ marginRight: 0 }} /> }
    </Box>
  )
}

export default FilterCategory
