export const localization = {
  en: {
    montShort: {
      0: 'Jan',
      1: 'Feb',
      2: 'Mar',
      3: 'Apr',
      4: 'May',
      5: 'Jun',
      6: 'Jul',
      7: 'Aug',
      8: 'Sep',
      9: 'Oct',
      10: 'Nov',
      11: 'Dec',
    },
  },
  fr: {
    montShort: {
      0: 'Jan',
      1: 'Fév',
      2: 'Mar',
      3: 'Avr',
      4: 'Mai',
      5: 'Jui',
      6: 'Juil',
      7: 'Aoû',
      8: 'Sep',
      9: 'Oct',
      10: 'Nov',
      11: 'Déc',
    },
  },
  es: {
    montShort: {
      0: 'Ene',
      1: 'Feb',
      2: 'Mar',
      3: 'Abr',
      4: 'May',
      5: 'Jun',
      6: 'Jul',
      7: 'Ago',
      8: 'Sep',
      9: 'Oct',
      10: 'Nov',
      11: 'Dic',
    },
  },
  de: {
    montShort: {
      0: 'Jan',
      1: 'Feb',
      2: 'Mär',
      3: 'Apr',
      4: 'Mai',
      5: 'Jun',
      6: 'Jul',
      7: 'Aug',
      8: 'Sep',
      9: 'Okt',
      10: 'Nov',
      11: 'Dez',
    },
  },
}
