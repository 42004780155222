import restapi from 'shared-module/api/restapi'
import buildPhoto from 'photo-module/photo/api/photoBridge'
import handleError from 'shared-module/api/apiError'

const getPhotoById = (cameraId, photoId) =>
  restapi.get(`/api/v3/photo/${cameraId}/${photoId}`)
    .then((response) => buildPhoto(response.data))

const getAllPhotos = (filters, isShared) => {
  const url = isShared ? 'api/v3/shared-cameras/photos' : '/api/v3/photo/all'
  return restapi.post(url, filters)
    .then(response => (response?.data?.photos?.map(photo => buildPhoto(photo))))
    .catch(handleError)
}

const getAllPhotosByCamera = (camera, filters, dateStart, dateEnd) => {
  return restapi.post(`/api/v3/photo/${camera.id}`, { tag: filters, limit: 100, dateStart: dateStart, dateEnd: dateEnd })
    .then(response => response?.data?.photos && response?.data?.photos?.map(photo => buildPhoto(photo, camera)))
    .catch(handleError)
}

const getAllFilters = (camera) => {
  return restapi.get(`/api/v3/photo/filters?cameraModel=${camera.status.model}`)
    .then(response => response.data)
    .catch(handleError)
}

const setFavorite = (cameraId, photoIds) => {
  return restapi.post(`/api/v3/photo/favorite/${cameraId}`, { photoIds })
}

const deleteFavorite = (cameraId, photoIds) => {
  return restapi.delete(`/api/v3/photo/favorite/${cameraId}`, { data: { photoIds } })
}

const deletePhotos = (cameraId, photoIds) => {
  return restapi.delete(`/api/v3/photo/${cameraId}`, { data: { photoIds } })
    .then((response) => response.data)
}

const requestHdPhoto = (cameraId, photoId) => {
  return restapi.post(`/api/v3/photo/hd/${cameraId}/${photoId}`)
    .then((response) => response.data)
}

const getHdPhotoStatus = (cameraId, photoId) =>
  restapi.get(`/api/v3/photo/hd/${cameraId}/${photoId}`)
    .then((response) => response.data)

const getEvents = (cameraArray, lastDate) => {
  const requestBody = cameraArray.map(camera => ({
    cameraId: camera.id,
    lastDate: lastDate,
  }))
  return restapi.post('/api/v3/camera/events/', requestBody)
    .then((response) => {
      return response.data.map(camera => ({
        cameraId: camera.cameraId,
        latestPhotos: camera.latestPhotos.map(photo => buildPhoto(photo)),
      }))
    })
    .catch(handleError)
}

export default {
  getPhotoById,
  getAllPhotos,
  getAllPhotosByCamera,
  getAllFilters,
  setFavorite,
  deleteFavorite,
  deletePhotos,
  requestHdPhoto,
  getHdPhotoStatus,
  getEvents,
}
