import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const HelpIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-testid="HelpIcon">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM12 7.41667C10.7936 7.41667 9.83325 8.37693 9.83325 9.58334H8.16658C8.16658 7.45641 9.87313 5.75 12 5.75C14.1269 5.75 15.8334 7.45641 15.8334 9.58334C15.8334 11.0827 14.9729 12.3619 13.7061 12.9953C13.1609 13.2679 12.8333 13.8091 12.8333 14.4167H11.1667C11.1667 13.1909 11.8392 12.0653 12.9607 11.5047C13.6939 11.138 14.1667 10.4173 14.1667 9.58334C14.1667 8.37693 13.2064 7.41667 12 7.41667ZM13.0002 17.2501C13.0002 17.8023 12.5525 18.2501 12.0002 18.2501C11.4479 18.2501 11.0002 17.8023 11.0002 17.2501C11.0002 16.6978 11.4479 16.2501 12.0002 16.2501C12.5525 16.2501 13.0002 16.6978 13.0002 17.2501Z"
        fill={theme.palette.text.primary}
      />

    </SvgIcon>
  )
}

export default HelpIcon
