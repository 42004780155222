import { getBatteryIcon } from 'camera-module/camera/core/getBatteryIcon'

const BatteryIcon = ({ percentage, viewBox = '0 0 32 32' }) => {
  const Battery = getBatteryIcon(percentage)
  return (
    <svg width="32" height="32" viewBox={viewBox}>
      <Battery />
    </svg>
  )
}

export default BatteryIcon
