import clsx from 'clsx'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import CloseIcon from '@material-ui/icons/Close'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useHomePageFlag } from 'vosker/src/launchDarkly-module/useHomePageFlag'

const useStyles = makeStyles(theme => ({
  legacyList: {
    paddingTop: theme.spacing(0.65),
    paddingLeft: theme.spacing(1),
  },
  legacyButton: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  list: {
    paddingLeft: theme.spacing(1.65),
  },
  listItem: {
    paddingRight: 0,
    justifyContent: 'space-between',
  },
  listItemIcon: {
    justifyContent: 'space-evenly',
  },
  button: {
    fill: theme.palette.text.secondary + '!important',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

const MenuHeader = ({ iconClass, onClick, Logo }) => {
  const classes = useStyles()
  const homePageEnabled = useHomePageFlag()

  return homePageEnabled
    ? (
      <List className={classes.list}>
        <ListItem className={classes.listItem}>
          <Logo style={{ width: '150px', height: '100%', marginLeft: 2.5 }} />

          <ListItemIcon className={classes.listItemIcon}>
            <CloseIcon fontSize="small" onClick={onClick} className={clsx(iconClass, classes.button)} />
          </ListItemIcon>
        </ListItem>
      </List>
      )
    : (
      <List className={classes.legacyList}>
        <ListItem>
          <ListItemIcon><CloseIcon fontSize="large" onClick={onClick} className={classes.legacyButton} /></ListItemIcon>
          <Logo style={{ width: '150px', height: '100%' }} />
        </ListItem>
      </List>
      )
}

export default MenuHeader
