import { Box, useTheme } from '@material-ui/core'

import FilterCategory from 'vosker/src/photos-module/photos-gallery/ui/filters-bar/FilterCategory'
import { FILTER_ICON_BY_KEY, TIME_FILTER_KEY } from '../../core/filters.types'
import FilterItem from './FilterItem'
import TimePeriodIcon from 'assets/icons/photo/TimePeriodIcon'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTimePeriodFilter } from 'vosker/src/launchDarkly-module/useTimePeriodFilter'

const FilterList = ({ filterKeys, filterValues, camera, setShowButtonSection }) => {
  const theme = useTheme()
  const timePeriodEnabled = useTimePeriodFilter()
  const breakpoint = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    <Box display="flex" flexWrap="wrap" alignItems="center" margin="12px 16px 0px 20px">
      { breakpoint && timePeriodEnabled && <FilterCategory key={TIME_FILTER_KEY} Icon={TimePeriodIcon} label={TIME_FILTER_KEY} camera={camera} setShowButtonSection={setShowButtonSection} /> }

      { filterValues && filterValues.map((filterValue, index) => {
        return filterValue.length === 1
          ? <FilterItem key={filterValue} Icon={FILTER_ICON_BY_KEY[filterKeys[index]]} hasCategory={false} label={filterValue[0]} />
          : <FilterCategory key={filterValue} Icon={FILTER_ICON_BY_KEY[filterKeys[index]]} category={filterKeys[index]} label={filterKeys[index]} values={filterValue} camera={camera} />
      }) }

      { !breakpoint && timePeriodEnabled && <FilterCategory key={TIME_FILTER_KEY} Icon={TimePeriodIcon} label={TIME_FILTER_KEY} camera={camera} setShowButtonSection={setShowButtonSection} /> }
    </Box>
  )
}

export default FilterList
