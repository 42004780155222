import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Observer from 'react-intersection-observer'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import InfoRoundedIcon from '@material-ui/icons/InfoRounded'

import PhotosDay from 'photo-module/photos/ui/photos-gallery/PhotosDay'
import { getAllPhotosByCamera, resetPhotos } from 'photo-module/photos/core/photosActions'
import Spinner from 'shared-module/components/Spinner'
import usePhotosByDate from 'photo-module/photos/ui/photos-gallery/usePhotosByDate'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import VoskerSecondaryButton from 'vosker/src/components/buttons/VoskerSecondaryButton'
import WarningIcon from 'assets/icons/navigation/WarningIcon'
import CTypography from 'storybook-component-module/src/components/typographies/CTypography'
import { apiCameraPlans } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.types'
import useFilterCollection from 'photo-module/filters-bar/core/filters.hooks'

const useStyles = makeStyles(theme => ({
  textmessage: {
    margin: 'auto',
    color: theme.palette.background.filter,
    textAlign: 'center',
  },
  noPlanContainer: {
    textAlign: 'center',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '250px',
    },
  },
  infoIcon: {
    fill: theme.palette?.status?.warning + ' !important',
    width: '1rem',
    top: '0.375rem',
    position: 'relative',
    marginRight: '0.313rem',
  },
  noPlanTextTitle: {
    fontWeight: 600,
  },
  noPlanTextContent: {
    color: theme.palette.text.secondary,
    marginTop: '4px',
    fontWeight: 400,
  },
  noPlanButton: {
    width: 'max-content',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '16px',
    },
  },
  warningIcon: {
    width: '1rem',
    top: '0.375rem',
    position: 'relative',
    marginRight: '0.313rem',
  },
  scrollableDiv: {
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    maxHeight: 'calc(100vh - 380px)',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 'calc(100vh - 412px)',
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: 'calc(30vh)',
      maxHeight: 'calc(100vh - 280px)',
    },
  },
}))

const BlockPhotoGallery = ({ photosGroupedByDate, PhotoOverlay, isNewUi = true, setIsIntersecting }) => {
  return photosGroupedByDate.map((photo) => (
    <PhotosDay
      key={photo.photos[photo.photos.length - 1].id}
      date={photo.date}
      photosList={photo.photos}
      PhotoOverlay={PhotoOverlay}
      isNewUi={isNewUi}
      setIsIntersecting={setIsIntersecting}
    />
  ),
  )
}

const PhotoGallery = ({ PhotoOverlay }) => {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const { t } = useTranslation()
  const location = useLocation()
  const dispatch = useDispatch()
  const camera = useSelectedCamera()
  const photosByDates = usePhotosByDate(camera?.id)
  const breakpoint = !useMediaQuery(theme.breakpoints.down('xs'))
  const { selectedFilters, hasTimePeriod, timePeriodValues } = useFilterCollection()

  const [isIntersecting, setIsIntersecting] = useState(false)
  const { fetching: isPhotosFetching, allPhotosLoaded, photos } = useSelector(state => state.galleryPhoto)
  const [timePeriodFormattedValues, setTimePeriodFormattedValues] = useState(null)

  const hasPlan = camera?.subscription?.plan?.id !== apiCameraPlans.Empty
  const checkPhotoReload = (!allPhotosLoaded && isIntersecting && !isPhotosFetching)

  const showLoader = (isPhotosFetching || photos.length === 0) && !allPhotosLoaded
  const isConfirmActivationPath = location.state && location.state.ActivationPath

  useEffect(() => {
    dispatch(resetPhotos())
  }, [selectedFilters, timePeriodFormattedValues, dispatch])

  useEffect(() => {
    if (hasTimePeriod) {
      const startDate = new Date(timePeriodValues[0])
      startDate.setUTCHours(0, 0, 0, 0)
      const endDate = new Date(timePeriodValues[1])
      endDate.setUTCHours(23, 59, 59, 999)

      setTimePeriodFormattedValues({ dateStart: startDate.toISOString(), dateEnd: endDate.toISOString() })
    } else setTimePeriodFormattedValues(null)
  }, [hasTimePeriod, timePeriodValues])

  useEffect(() => {
    if (checkPhotoReload) {
      dispatch(getAllPhotosByCamera(camera, selectedFilters, timePeriodFormattedValues))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photos, checkPhotoReload, dispatch, camera, selectedFilters])

  const observerOnChange = (isVisible) => setIsIntersecting(isVisible)

  const renderNoPhotosMessage = () => {
    if (!allPhotosLoaded || photos.length > 0) {
      return null
    }

    const noFilteredPhoto = (<p>{ t('photo_gallery.no_filtered_photos') }</p>)

    const noPhoto = (
      <Grid container display="flex" justifyContent="center" style={{ marginBottom: breakpoint ? '72px' : undefined }}>
        <Grid item md={6} xs={12}>
          <CTypography variant="body1" color="textPrimary" className={classes.noPlanTextTitle}><InfoRoundedIcon className={classes.infoIcon} />{ camera.isCellular ? t('photo_gallery.no_photos', { cameraName: camera.config.name }) : t('photo_gallery.no_photos_no_cellular') }</CTypography>
          <CTypography variant="body2" className={classes.noPlanTextContent}>{ camera.isCellular ? t('photo_gallery.is_cellular') : t('photo_gallery.is_not_cellular') }</CTypography>
        </Grid>
      </Grid>
    )

    const textToShow = isConfirmActivationPath
      ? (
        <div>
          <p>
            { camera.isCellular
              ? t('photo_gallery.no_photos', { cameraName: camera.config.name })
              : t('photo_gallery.no_photos_no_cellular') }
          </p>
          <p>
            { camera.isCellular
              ? t('photo_gallery.is_cellular_new_activation')
              : t('photo_gallery.is_not_cellular') }
          </p>
        </div>
        )
      : noPhoto

    return (
      <div className={classes.textmessage}>
        { selectedFilters.length > 0 || hasTimePeriod
          ? noFilteredPhoto
          : textToShow }
      </div>
    )
  }

  if (!hasPlan) {
    return (
      <Box style={{ width: '100%', marginBottom: breakpoint ? '72px' : undefined }}>
        <Box className={classes.noPlanContainer}>
          <CTypography variant="body1" color="textPrimary" style={{ fontWeight: 600 }}><WarningIcon className={classes.warningIcon} /> { t('photo_gallery.plan_expires') }</CTypography>
          <CTypography variant="body2" className={classes.noPlanTextContent}> { t('photo_gallery.no_plan') }</CTypography>
        </Box>
        <Box display="flex" justifyContent="center" mt="24px">
          <VoskerSecondaryButton className={classes.noPlanButton} py={4} onClick={() => history.push(`/camera/${camera.id}/plan`)}>
            { t('photo_gallery.choose_plan_button') }
          </VoskerSecondaryButton>
        </Box>
      </Box>
    )
  }

  return (
    <div className={!(allPhotosLoaded && photos.length === 0) ? classes.scrollableDiv : ''} style={{ textAlign: 'center', width: '100%' }}>
      { renderNoPhotosMessage() }
      <BlockPhotoGallery hasPlan={hasPlan} photosGroupedByDate={photosByDates} PhotoOverlay={PhotoOverlay} setIsIntersecting={setIsIntersecting} />
      <Observer onChange={observerOnChange}>
        { showLoader && <Spinner spinnerMarginTop="10px" position="relative" /> }
      </Observer>
    </div>
  )
}

export default PhotoGallery
