import { stringify } from 'qs'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import webview from 'shared-module/webview/webview'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { useCameras } from 'camera-module/cameras/core/useCameras'
import powerModes from 'camera-module/camera/core/powerModeSettings'
import Price from 'vosker/src/camera-module/transmission-plan/ui/components/Price'
import { getApiAddOnId } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.selectors'
import { cameraAddOns } from 'vosker/src/camera-module/transmission-plan/core/cameraAddOns.types'
import DialogBuyAddonConfirm from 'vosker/src/camera-module/transmission-plan/ui/content/DialogBuyAddonConfirm'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'
import VoskerPrimaryButton from 'vosker/src/components/buttons/VoskerPrimaryButton'
import { useBasket } from 'vosker/src/ecommerce-module/core/basket/BasketContext'
import eCommerceActions from 'vosker/src/ecommerce-module/core/eCommerce.actions'
import useUser from 'user-module/user/core/useUser'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import { useECommerceFlag } from 'vosker/src/launchDarkly-module/useEcommerceFlag'

const useStyles = makeStyles(theme => ({
  titleContainer: {
    width: '100%',
    padding: '0 1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.light,
    borderRadius: '0.375rem 0.375rem 0rem 0rem',
  },
  descriptionContainer: {
    padding: '1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  textContainer: {
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  description: {
    fontSize: theme.typography.body1?.fontSize,
    fontFamily: theme.typography.body1?.fontFamily,
    padding: '0 0.25rem',
  },
  button: {
    minHeight: '2.5rem',
    width: 'auto',
    [theme.breakpoints.down('xs')]: {
      minHeight: '2rem',
      fontSize: '0.75rem',
    },
  },
  footNoteTitleIndex: {
    fontSize: 10,
    marginLeft: '0.25rem',
    display: 'inline',
  },
  footNoteIndex: {
    fontSize: 10,
    display: 'inline',
    bottom: '0.25rem',
    marginLeft: '0.25rem',
    position: 'relative',
  },
  plusSign: {
    fontWeight: 'bold',
    display: 'inline',
    marginRight: '0.25rem',
  },
}))

const AddOnCard = ({ camera, addOn }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const cameras = useCameras()
  const dispatch = useDispatch()
  const user = useUser()
  const selectedCamera = useSelectedCamera()

  const eCommerceLdEnabled = useECommerceFlag()
  const eCommerceAddonID = eCommerceLdEnabled && addOn.name.toString().split(' ').join('_').toLowerCase()
  const buyAddonDialogActions = useConfirmationDialogActions()
  const ecoModeCameras = cameras?.filter(cam => cam.config?.powerMode && cam.config?.powerMode === powerModes.Eco)

  const { addProductToBasket } = useBasket()

  const proceedToOldCheckOut = () => {
    if (buyAddonDialogActions.isOpen) { buyAddonDialogActions.close() }

    const products = {
      p: [{
        id: 'AddOn',
        addOnId: getApiAddOnId(addOn.id),
        cameraId: camera.id,
        quantity: 1,
      }],
    }

    if (webview.isWebview()) {
      webview.postMessage({
        action: 'navigate',
        to: 'checkout',
        options: {
          cameraId: camera.id,
          addOnId: getApiAddOnId(addOn.id),
          quantity: 1,
        },
      })
      return
    }

    const params = stringify(products, { encode: false })
    history.push(`/order?${params}${webview.isWebview() ? '&w=1' : ''}`)
  }

  const proceedToNewCheckOut = async () => {
    if (buyAddonDialogActions.isOpen) {
      buyAddonDialogActions.close()
    }

    const products = [{
      productId: addOn.id,
      name: addOn.name,
      quantity: addOn.stepQuantity,
      price: addOn.price,
      cameraId: selectedCamera.id,
      cameraName: selectedCamera.config.name,
    }]

    await dispatch(eCommerceActions.proceedToPayment(selectedCamera, user, addProductToBasket, products))
  }

  const proceedToCheckOut = async () => {
    try {
      if (eCommerceLdEnabled) {
        await proceedToNewCheckOut()
      } else {
        proceedToOldCheckOut()
      }
    } catch (error) {
      return error
    }
  }

  const onClick = () => {
    if (eCommerceLdEnabled) {
      eCommerceAddonID === cameraAddOns.streaming && ecoModeCameras.length > 0
        ? buyAddonDialogActions.open()
        : proceedToCheckOut()
    } else {
      addOn.id === cameraAddOns.streaming && ecoModeCameras.length > 0
        ? buyAddonDialogActions.open()
        : proceedToCheckOut()
    }
  }

  return (
    <>
      <Grid item className={classes.titleContainer}>
        <Box display="inline-flex">
          <Typography variant="h2">
            { eCommerceLdEnabled
              ? t(`app:plan.addon.${eCommerceAddonID}.name`)
              : t(`app:plan.addon.${addOn.id}.name`) }
          </Typography>
        </Box>

        <Price noPadding price={addOn.price} />
      </Grid>

      <Grid item className={classes.descriptionContainer}>
        <Grid item xs={10} sm={11} md="auto" className={classes.textContainer}>
          { (addOn.id === cameraAddOns.streaming || eCommerceAddonID === cameraAddOns.streaming) && (
            <Typography className={classes.description}>
              <Typography className={classes.plusSign}>+</Typography>
              { eCommerceLdEnabled
                ? t(`app:plan.addon.${eCommerceAddonID}.descriptions.full`, { count: addOn.minutesSd })
                : t(`app:plan.addon.${addOn.id}.descriptions.full`, { count: addOn.minutesOfSdStreaming }) }
              <Typography className={classes.footNoteIndex}>2</Typography>
            </Typography>
          ) }
        </Grid>

        <Grid container item xs sm md className={classes.textContainer}>
          <VoskerPrimaryButton
            id={addOn.id + '_addon_button'}
            onClick={onClick}
            className={classes.button}
            data-testid="addon-add-button"
          >
            { t('app:plan.addon.buy_button') }
          </VoskerPrimaryButton>
        </Grid>
      </Grid>

      <DialogBuyAddonConfirm
        open={buyAddonDialogActions.isOpen}
        onClose={buyAddonDialogActions.close}
        onConfirm={proceedToCheckOut}
        ecoModeCameras={ecoModeCameras}
      />
    </>
  )
}

export default AddOnCard
