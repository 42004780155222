import LogoutIcon from 'assets/icons/navigation/LogoutIcon'
import MenuItem from 'shared-module/navigation/menu/MenuItem'
import useUserActions from 'user-module/user/core/useUserActions'

const LogoutMenuItem = ({ onClick, iconClass }) => {
  const userActions = useUserActions()

  const logout = () => {
    onClick()
    userActions.logout()
  }

  return (
    <MenuItem id="Logout" Icon={LogoutIcon} text="menu.logout" iconClass={iconClass} onClick={logout} />
  )
}

export default LogoutMenuItem
