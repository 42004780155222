import { useTheme } from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'

const MagnifyingIcon = () => {
  const theme = useTheme()

  return (
    <SvgIcon data-testid="MagnifyingIcon" viewBox="0 0 20 20" fill={theme.palette.text?.secondary} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.39398 1.25C6.49928 1.25 4.68218 2.00778 3.34242 3.35665C2.00267 4.70551 1.25 6.53497 1.25 8.44255C1.25 10.3501 2.00267 12.1796 3.34242 13.5285C4.68218 14.8773 6.49928 15.6351 8.39398 15.6351C9.89227 15.6351 11.342 15.1612 12.5456 14.2959L17.0887 18.75L18.75 17.0323L14.2259 12.5968C15.0739 11.3902 15.538 9.94044 15.538 8.44255C15.538 6.53497 14.7853 4.70551 13.4455 3.35665C12.1058 2.00778 10.2887 1.25 8.39398 1.25ZM5.02628 5.05195C5.91945 4.15271 7.13085 3.64752 8.39398 3.64752C9.65711 3.64752 10.8685 4.15271 11.7617 5.05195C12.6549 5.95119 13.1566 7.17083 13.1566 8.44255C13.1566 9.71427 12.6549 10.9339 11.7617 11.8331C10.8685 12.7324 9.65711 13.2376 8.39398 13.2376C7.13085 13.2376 5.91945 12.7324 5.02628 11.8331C4.1331 10.9339 3.63133 9.71427 3.63133 8.44255C3.63133 7.17083 4.1331 5.95119 5.02628 5.05195Z"
        fill={theme.palette.text?.secondary}
      />
    </SvgIcon>
  )
}

export default MagnifyingIcon
