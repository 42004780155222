import { useLDFlags } from 'vosker/src/launchDarkly-module/launchDarklyFlagsContext'
import { useEffect } from 'react'
import { eCommerceLDFlag } from 'vosker/src/ecommerce-module/core/eCommerce.selectors'

export const useECommerceFlag = () => {
  const { flags } = useLDFlags()

  /*
   * In order to properly handle error cases for calls made to salesforce,
   * the state of the feature switch must be persisted in localstorage and then retrieved in the axios interceptor.
   *
   * Using hooks is not allowed in a functional component, so we must retrieve this state in another way.
   */
  useEffect(() => {
    flags && localStorage.setItem(eCommerceLDFlag, flags['e-commerce'])
  }, [flags])

  return flags && flags['e-commerce']
}
