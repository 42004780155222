import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import usePhotoActions from 'photo-module/photo/core/usePhotoActions'

const usePhoto = (cameraId, photoId) => {
  const state = useSelector(state => state.photo)
  const photoAction = usePhotoActions()

  const [currentPhoto, setCurrentPhoto] = useState(null)

  useEffect(() => {
    if (cameraId && photoId) {
      if (!state.photo || state.photo.id !== photoId || !photoAction.isPhotoReady(state)) {
        photoAction.get(cameraId, photoId)
      }
    }
  }, [cameraId, photoId, state.photo, photoAction, state])

  useEffect(() => {
    if (state.photo && state.photo.id === photoId) {
      setCurrentPhoto(state.photo)
    }
  }, [state.photo, photoId])

  return currentPhoto
}

export default usePhoto
