import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import VoskerInputField from 'vosker/src/components/buttons/VoskerInputField'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    paddingRight: 16,
    paddingLeft: 16,
    paddingBottom: 4,
    paddingTop: 16,
    '& .MuiInputBase-input': {
      cursor: 'default',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      opacity: 1 + '!important',
      color: theme.palette.text.primary,
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: theme.palette.text.secondary,
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      border: `1.5px solid ${theme.palette.border?.secondary} !important`,
    },
    '&:hover .MuiOutlinedInput-root': {
      borderRadius: 6,
      border: 'inherent !important',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, 20px) scale(1)',
    },
    '& label.Mui-focused': {
      color: theme.palette.text.secondary,
    },
    '& .MuiFormLabel-asterisk': {
      visibility: 'hidden',
    },
  },
  containerWithValue: {
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, 10px) scale(0.75) !important',
    },
  },
  textfield: {
    paddingBottom: 8,
  },
}))

const TimePeriodForm = ({ startDateValue, startDateFocusState, endDateValue, endDateFocusState, formattedStartDate, formattedEndDate }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Grid container item xs={12} className={classes.container}>
      <Grid item xs={12}>
        <VoskerInputField
          required
          defaultValue={formattedStartDate}
          name="startDate"
          label={t('app:filters.values.startDate')}
          disabled={!startDateFocusState}
          focused={startDateFocusState}
          inputProps={{ readOnly: true }}
          className={startDateValue !== '' ? clsx(classes.containerWithValue, classes.textfield) : classes.textfield}
        />
      </Grid>

      <Grid item xs={12}>
        <VoskerInputField
          required
          defaultValue={formattedEndDate}
          name="endDate"
          label={t('app:filters.values.endDate')}
          disabled={!endDateFocusState}
          focused={endDateFocusState}
          inputProps={{ readOnly: true }}
          className={endDateValue !== '' ? clsx(classes.containerWithValue, classes.textfield) : classes.textfield}
        />
      </Grid>
    </Grid>
  )
}

export default TimePeriodForm
