import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@mui/material'

const PlanIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon data-testid="PlanIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0241 2L20.2825 3.90949C20.2825 3.90949 21.3972 11.8227 20.6531 13.962C19.9091 16.1013 16.4603 19.3528 14.3497 20.6268C13.5865 21.1252 12.798 21.5836 11.9873 22H11.9732C11.1625 21.5836 10.374 21.1252 9.61078 20.6268C7.53981 19.3528 4.09102 16.0814 3.34694 13.962C2.60286 11.8425 3.71757 3.90949 3.71757 3.90949L11.9732 2H12.0241ZM16.8862 8.51826L11.0592 14.3453C10.8721 14.5324 10.6182 14.6376 10.3534 14.6376C10.0887 14.6376 9.83482 14.5324 9.64762 14.3453L7.09546 11.7918L8.4811 10.3552L10.3534 12.2277L15.4746 7.1066L16.8862 8.51826Z"
        fill={`${theme.palette.text.primary} !important`}
      />
    </SvgIcon>
  )
}

export default PlanIcon
