import Box from '@material-ui/core/Box'
import { useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import FilterLabel from './FilterLabel'

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: -4,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '4px 10px 4px 4px',
    borderRadius: 5,
    padding: '5px 10px 5px 10px',
    maxHeight: '2.5rem',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('xs')]: {
      marginLeft: -10,
      marginRight: -10,
      flexDirection: 'column',
      maxHeight: 'inherit',
      margin: '0px',
      padding: '4px 10px',
      alignItems: 'flex-start',
    },
  },
  divider: {
    marginBottom: '0px !important',
    marginTop: '0px !important',
    color: theme.palette.border?.divider,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '1px',
      backgroundColor: theme.palette.border?.divider,
    },
  },
}))

const FilterItem = ({ Icon, hasCategory, label }) => {
  const classes = useStyles()
  const theme = useTheme()
  const breakpoint = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <div className={classes.root}>
      <Box className={classes.box}>
        <FilterLabel
          Icon={Icon}
          label={label}
          hasCategory={hasCategory}
          style={{ padding: 0, margin: breakpoint ? '0px 0px 0px 0px' : '-1px 0px', borderBottom: breakpoint && `1px solid ${theme.palette.border?.divider}` }}
        />
        { /* { breakpoint && <Divider className={classes.divider} /> } */ }
      </Box>
    </div>
  )
}

export default FilterItem
