import { useTheme } from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'

const HomePlanIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon data-testid="HomePlanIcon" width="16" height="16" viewBox={props.viewBox || '0 0 16 16'} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.01598 1.33337L13.5216 2.60637C13.5216 2.60637 14.2647 7.88182 13.7687 9.30803C13.2726 10.7342 10.9734 12.9019 9.56638 13.7512C9.05757 14.0835 8.53189 14.3891 7.99146 14.6667H7.98203C7.4416 14.3891 6.91591 14.0835 6.40711 13.7512C5.02646 12.9019 2.72726 10.721 2.23121 9.30803C1.73516 7.89506 2.4783 2.60637 2.4783 2.60637L7.98203 1.33337H8.01598ZM11.2574 5.67888L7.37275 9.56355C7.24796 9.68834 7.07872 9.75845 6.90221 9.75845C6.72572 9.75845 6.55646 9.68834 6.43167 9.56355L4.73022 7.86125L5.65399 6.90353L6.90222 8.15187L10.3163 4.73777L11.2574 5.67888Z"
        fill={theme.palette.text.primary}
      />
    </SvgIcon>
  )
}

export default HomePlanIcon
