import { useTheme } from '@mui/material'
import SvgIcon from '@material-ui/core/SvgIcon'

const ActivateCameraIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon data-testid="ActivateCameraIcon" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2C2.89543 2 2 2.89543 2 4V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V4C22 2.89543 21.1046 2 20 2H4ZM11.3106 17.55V12.6889H6.44946V11.3H11.3106V6.43892H12.6995V11.3H17.5606V12.6889H12.6995V17.55H11.3106Z"
        fill={`${theme.palette.text.primary} !important`}
      />
    </SvgIcon>
  )
}

export default ActivateCameraIcon
