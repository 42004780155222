import { useTranslation } from 'react-i18next'

import clsx from 'clsx'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { useHomePageFlag } from 'vosker/src/launchDarkly-module/useHomePageFlag'
import { Mixpanel } from 'vosker/src/Mixpanel'
import useUser from 'user-module/user/core/useUser'

const useStyles = makeStyles(theme => ({
  legacyMenuLink: {
    fontFamily: theme.typography.menuLink?.fontFamily,
    fontSize: theme.typography.menuLink?.fontSize,
    lineHeight: theme.typography.menuLink?.lineHeight,
  },
  legacyItem: {
    paddingLeft: theme.spacing(4),
    '&:hover': {
      background: theme.palette.background.paper,
    },
  },
  item: {
    paddingLeft: theme.spacing(4),
    paddingTop: '0.8rem',
    paddingBottom: '0.8rem',
    '& .MuiList-padding': {
      padding: 0,
    },
    '&:hover': {
      background: theme.palette.background?.empty,
    },
  },
  selected: {
    borderLeft: `6px solid ${theme.palette.status?.info}`,
    background: theme.palette.border?.primary,
    paddingLeft: 26,
    '&:hover': {
      background: theme.palette.background?.empty,
    },
  },
  icon: {
    width: '24px !important',
    height: '24px !important',
  },
  menuLink: {
    fontFamily: theme.typography.body1?.fontFamily,
    fontSize: theme.typography.body1?.fontSize,
    fontWeight: 600,
    lineHeight: '21px',
  },
}))

const MenuItem = ({ id, Icon, text, onClick, iconClass, isSelected, children }) => {
  const user = useUser()
  const { t } = useTranslation()
  const classes = useStyles()
  const homePageEnabled = useHomePageFlag()

  const handleOnClick = async () => {
    await Mixpanel.track('Menu Navigation', { 'User Id': user.id, 'Menu Item': id })
    onClick && onClick()
  }

  const listItemClass = homePageEnabled
    ? isSelected ? clsx(classes.item, classes.selected) : classes.item
    : classes.legacyItem

  return (
    <ListItem button className={listItemClass} onClick={handleOnClick}>
      <ListItemIcon><Icon fontSize="small" className={homePageEnabled ? clsx(classes.icon, iconClass) : ''} /></ListItemIcon>
      <ListItemText primary={t(text)} primaryTypographyProps={{ className: homePageEnabled ? classes.menuLink : classes.legacyMenuLink }} />
      <ListItemIcon>{ children }</ListItemIcon>
    </ListItem>
  )
}

export default MenuItem
