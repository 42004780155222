import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import useCameraModelsAndVariants from 'camera-module/model/core/useCameraModelsAndVariants'
import Title from 'shared-module/new-components/layout/Title'
import { useHomePageFlag } from 'vosker/src/launchDarkly-module/useHomePageFlag'

const useStyles = makeStyles(theme => ({
  text: {
    color: theme.palette.background?.shadow,
  },
  oldText: {
    color: theme.palette.text?.primary,
  },
}))

const CameraInfo = () => {
  const classes = useStyles()
  const camera = useSelectedCamera()
  const model = useCameraModelsAndVariants(camera.status.model)
  const homePageEnabled = useHomePageFlag()

  return model
    ? (
      <Box pl={homePageEnabled ? 0.75 : 2} display="flex">
        <Box height={homePageEnabled ? 56 : 70} width={homePageEnabled ? 56 : 70} overflow="hidden" style={{ position: 'relative' }}>
          <img src={model.icon} alt={model.name} style={{ position: 'absolute', bottom: homePageEnabled ? '4' : '0', width: '70%' }} />
        </Box>
        <Box display="flex" flexDirection="column" mt={homePageEnabled ? 1 : 2} ml={homePageEnabled ? 0.75 : 0} maxWidth={homePageEnabled ? 200 : 'none'}>
          <Title h="h2" mb={1} noWrap maxWidth="250px" className={homePageEnabled ? classes.text : classes.oldText}>{ camera.config.name }</Title>
          <Title h={homePageEnabled ? 'label' : 'h3'} mt={homePageEnabled ? -0.75 : 0} mb={!homePageEnabled ? 0 : 'auto'} className={homePageEnabled ? classes.text : classes.oldText}>{ model.name }</Title>
        </Box>
      </Box>
      )
    : null
}

export default CameraInfo
