import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

import { useTranslation } from 'react-i18next'
import useFilterCollection from 'photo-module/filters-bar/core/filters.hooks'
import FilterCounterDrawer from './FilterCounterDrawer'
import VoskerPrimaryButton from 'vosker/src/components/buttons/VoskerPrimaryButton'
import VoskerSecondaryButton from 'vosker/src/components/buttons/VoskerSecondaryButton'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '35px 8px 8px',
    height: '100%',
    justifyContent: 'space-between',
  },
  primary: {
    marginLeft: 8,
    marginRight: 16,
    width: '100%',
  },
  secondary: {
    marginLeft: 16,
    marginRight: 8,
    width: '100%',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    width: '100%',
    bottom: '0',
    padding: '24px 0px',
    left: '0',
    background: theme.palette.background?.paper,
  },
}))

const FilterDrawerContent = ({ filterKeys, filterValues, close, showButtonSection = true, setShowButtonSection, FilterList }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { clearSelectedFilters, setFilters, clearTimePeriod } = useFilterCollection()

  const onClose = () => {
    setFilters()
    close()
  }

  const clearAllFilters = () => {
    clearTimePeriod()
    clearSelectedFilters()
  }

  return (
    <Box className={classes.container}>
      <div>
        <FilterCounterDrawer close={close} />
        <FilterList filterKeys={filterKeys} filterValues={filterValues} setShowButtonSection={setShowButtonSection} />
      </div>

      { showButtonSection && (
        <Box className={classes.buttonsContainer}>
          <VoskerSecondaryButton className={classes.secondary} onClick={clearAllFilters}>
            { t('app:filters.buttons.clear_all') }
          </VoskerSecondaryButton>
          <VoskerPrimaryButton className={classes.primary} onClick={onClose}>
            { t('app:filters.buttons.apply') }
          </VoskerPrimaryButton>
        </Box>
      ) }
    </Box>
  )
}

export default FilterDrawerContent
