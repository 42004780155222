import VoskerPhotoCountElement from './VoskerPhotoCountElement'
import FullHdVideoCountElement from './FullHdVideoCountElement'
import StreamingMinutesElement from './StreamingMinutesElement'
import VoskerHdPhotoCountElement from './VoskerHdPhotoCountElement'
import VoskerTransmissionPlanNameElement from './VoskerTransmissionPlanNameElement'
import CameraNameElement from 'photo-module/photos/ui/photos-gallery/information-bar/elements/CameraNameElement'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'

const VoskerTopBarItems = ({ hideCameraName = false }) => {
  const camera = useSelectedCamera()
  const hasInstantModeCapabilities = camera.status.capability?.instantMode || camera?.config?.operationMode === 'instant'

  return (
    <>
      <VoskerPhotoCountElement />
      <VoskerHdPhotoCountElement />
      <FullHdVideoCountElement />
      { hasInstantModeCapabilities ? null : <StreamingMinutesElement /> }
      <VoskerTransmissionPlanNameElement />
      { hideCameraName ? null : <CameraNameElement /> }
    </>
  )
}

export default VoskerTopBarItems
