import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@material-ui/core/Typography'

import useFilterCollection from 'photo-module/filters-bar/core/filters.hooks'
import { isFilterSelected } from 'photo-module/filters-bar/core/filters.selectors'
import VoskerCheckbox from 'vosker/src/components/VoskerCheckbox'

const useStyles = makeStyles((theme) => ({
  unchecked: {
    width: '1rem',
    height: '1rem',
    fill: 'transparent !important',
    border: `1.5px solid ${theme.palette.checkbox?.primary}`,
    [theme.breakpoints.down('xs')]: {
      width: '1.5rem',
      height: '1.5rem',
    },
  },
  checked: {
    width: '1rem',
    height: '1rem',
    border: 'none !important',
    [theme.breakpoints.down('xs')]: {
      width: '1.5rem',
      height: '1.5rem',
    },
  },
  checkbox: {
    width: '1rem',
    height: '1rem',
    border: `1.5px solid ${theme.palette.checkbox?.primary}`,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '1.5rem',
    },
  },
  text: {
    fontWeight: 600,
  },
  textSmallScreen: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: 15,
    },
  },
  betaBanner: {
    background: theme.palette.secondary.main,
    borderRadius: 6,
    padding: '1px 4px 2px 4px',
    marginLeft: 10,
  },
  betaText: {
    fontSize: theme.typography?.caption2?.fontSize,
    fontWeight: 700,
  },
}))

const FilterLabel = ({ Icon, category, hasCategory = true, label, style }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { selectedFilters, addSelectedFilter, deleteSelectedFilter } = useFilterCollection()

  const [isChecked, setIsChecked] = useState(isFilterSelected(label, selectedFilters))
  const isVehicleCategory = category === 'vehicles'

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setIsChecked(isFilterSelected(label, selectedFilters)), [selectedFilters])

  const sxMenuItem = {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  }

  const setSelectedFilter = () => {
    isChecked ? deleteSelectedFilter(label) : addSelectedFilter(label)
    setIsChecked(!isChecked)
  }

  return (
    <MenuItem
      sx={sxMenuItem}
      style={{ ...style, justifyContent: 'space-between', width: '100%' }}
      disableTouchRipple
      onClick={setSelectedFilter}
    >
      <Box display="flex" flexDirection="row" alignItems="center">
        { Icon && <Icon style={{ marginBottom: 2, marginRight: 8 }} /> }
        { hasCategory
          ? (
            <Typography variant="body2" className={classes.textSmallScreen}>{ t('app:filters.tags.' + label) }</Typography>
            )
          : (
            <Typography variant="body2" className={classes.text} noWrap>
              { t('app:filters.tags.' + label) }
            </Typography>
            ) }
        { isVehicleCategory && label !== 'vehicle' && (
          <Box className={classes.betaBanner}>
            <Typography className={classes.betaText}>{ t('app:filters.tags.beta') }</Typography>
          </Box>
        ) }
      </Box>

      <VoskerCheckbox checked={isChecked} uncheckedClassName={classes.unchecked} checkedClassName={classes.checked} />
    </MenuItem>
  )
}

export default FilterLabel
