import FavoriteIcon from 'assets/icons/photo/FavoriteIcon'
import FullHdVideoIcon from 'assets/icons/video/FullHdVideoIcon'
import HumanIcon from 'vosker/src/assets/icons/filters/HumanIcon'
import MediaTypesIcon from 'vosker/src/assets/icons/filters/MediaTypesIcon'
import VehiclesIcon from 'vosker/src/assets/icons/filters/VehiclesIcon'
import DetectionZoneIcon from 'vosker/src/assets/icons/filters/DetectionZoneIcon'
import DayNightIcon from 'vosker/src/assets/icons/filters/DayNightIcon'
import AnimalsIcon from 'vosker/src/assets/icons/filters/AnimalsIcon'

export const TIME_FILTER_KEY = 'timePeriod'

export const FILTER_ICON_BY_KEY = {
  favorite: FavoriteIcon,
  human: HumanIcon,
  mediaTypes: MediaTypesIcon,
  vehicles: VehiclesIcon,
  animals: AnimalsIcon,
  weather: DayNightIcon,
  zone: DetectionZoneIcon,
  timeOfDay: DayNightIcon,
  hd: FullHdVideoIcon,
}
