import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import Hidden from '@material-ui/core/Hidden'
import useTheme from '@material-ui/core/styles/useTheme'

import PlanIcon from 'vosker/src/assets/PlanIcon'
import NewSmallCartLinkIcon from 'vosker/src/assets/icons/NewSmallCartLinkIcon'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import { useHomePageFlag } from 'vosker/src/launchDarkly-module/useHomePageFlag'

const VoskerCartLink = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const theme = useTheme()
  const camera = useSelectedCamera()
  const homePageEnabled = useHomePageFlag()

  const isShowing =
    history.location.pathname.toString().includes('settings') ||
    history.location.pathname.toString().includes('status') ||
    history.location.pathname.toString() === `/camera/${camera?.id}`

  const goToPlans = () => {
    history.push(`/camera/${camera?.id}/plan`)
  }

  return (
    isShowing && (
      <Box display="flex" justifyContent="flex-end" alignItems="center" paddingLeft={2}>

        { homePageEnabled
          ? <PlanIcon style={{ paddingRight: '4px', marginTop: '2px', width: '24px', height: '24px', color: theme.palette.primary.main }} onClick={goToPlans} />
          : <NewSmallCartLinkIcon style={{ paddingRight: '4px', marginTop: '2px', width: '24px', height: '24px', color: theme.palette.primary.main }} onClick={goToPlans} /> }

        <Hidden xsDown>
          <Link style={{ paddingTop: '2px' }} component="button" variant="body2" onClick={goToPlans}>{ t('app:nav_bar.go_to_plan_page') }</Link>
        </Hidden>
      </Box>
    )
  )
}

export default VoskerCartLink
